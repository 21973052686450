/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { makeStyles, Container } from "@material-ui/core";
import { Button } from 'devextreme-react/button';
import { Typography } from '@material-ui/core';
//import './custom.css';
import '../style/customStyle.css';
import $ from 'jquery';
import Fuse from 'fuse.js'
import { useAuth0 } from '@auth0/auth0-react';
import SelectClientRetailer from './SelectClientRetailer';
import RejectionTable from './RejectionsTable';
import { Db2ApiContext } from '../../contexts/db2ApiContext';

const useStyles = makeStyles((theme) => ({
    button: {
        display: "block",
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    helpcenter: {
        position: 'absolute',
        right: 5,
        top: 5,

    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100vh',
        margin: '-65px auto',
        alignItems: 'center',
    },
    btnHover: {
        //color: 'red',
        fontStyle: 'italic',

        textDecoration: 'none',
        borderBottom: 'dashed 1px #428bca',
        '&:hover': {
            textDecoration: 'none'
        },
        '& .goodSelection': {
            color: 'green'
        },
        '& .badSelection': {
            color: '#D14',
        }

    },
    btnIconHover: {
        fontSize: '14px',
        padding: 0,
        marginLeft: '10px',
        color: '#337ab7',
        '&:hover': {
            backgroundColor: 'transparent',
            color: '#23527c',
            textDecoration: 'underline'
        }

    },
    selectColor: {
        '& select': {
            padding: '0px 0 7px',
            fontSize: '15px'
        }
    },
    spinnerIcon: {
        color: '#54B948'
    }
}));

export default function DB2Page({ userClient, setIsLoading }) {
    const classes = useStyles();
    const { getClient, getDb2Style, getDb2StyleColor, getDb2StyleColorSize, getRejection, submit } = useContext(Db2ApiContext);

    let [retailerList, setRetailerList] = React.useState([]);
    let [clientData, setClientData] = React.useState([]);
    let [rejectionData, setRejectionData] = React.useState([]);
    let [selectedClient, setSelectedClient] = React.useState("");
    let [clientName, setClientName] = React.useState("");
    let [retailerName, setRetailerName] = React.useState("");
    let [dbInfo, setdbInfo] = React.useState([]);
    let [styleList, setStyleList] = React.useState([]);
    const [load1, setLoad1] = React.useState(true);
    let [colorStyle, setColorStyle] = React.useState("");
    let [tableLoadDone, setTableLoadDone] = React.useState(false);
    let updatedRows = {};
    let showStylePage = new Set();
    let dataGridRef = React.useRef();
    let updatedColorOptions = {};
    let updatedSizeOptions = {};
    let noRejections = false;
    const { user } = useAuth0();

    useEffect(async () => {
        if (userClient) {
            let data = await getClient(user, userClient);
            if (data.length !== 0) {
                setClientData(data);
            }
        }
        else {
            getClient(user)
                .then((data) => {
                    if (data.length !== 0) {
                        setClientData(data);
                    }
                    else {
                        noRejections = true
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, []);

    useEffect(() => {
        if (userClient && clientData.length > 0)
            handleChange1({ target: { value: clientData[0].CLIENT_NAME } });
    }, [clientData]);

    useEffect(() => {
        if (retailerList.length === 1) {
            handleChange2(retailerList[0])
        }
    }, [retailerList]);

    useEffect(() => {
        if (selectedClient) {
            getDb2Style(selectedClient, dbInfo)
                .then((response) => {
                    let styles = [];
                    response.forEach(element => {
                        styles.push(element.STYLE);
                    })
                    setStyleList(styles);
                    setTableLoadDone(true);
                });
        }
    }, [dbInfo]);

    useEffect(() => {
        if (rejectionData.length > 0) {
            let styles = [];
            //let allStyles = rejectionData.filter(x => x.rejection_cause === 'MISSING COLOR');
            let allRejectedStyles = rejectionData;
            if (allRejectedStyles.length > 0) {
                allRejectedStyles.forEach(element => {
                    if (element.STM_STYLE !== null) {
                        styles.push(element.STM_STYLE)//add the styles to an array
                    }

                })
            }
            //setStylesArray(styles);

            var stylesString = "";
            //create string of all styles to pass in the sql query
            // if (0 < styles.length && styles.length < 401) {
            if (0 < styles.length) {
                styles.forEach(function (value, index, arr) {
                    if (styles.length === 1) {
                        // stylesString = `'${value}'`;
                        stylesString = value;
                    }
                    else {
                        stylesString += `'${value}'`;
                    }
                    // stylesString += value;

                    if (index < arr.length - 1) {
                        stylesString += ", ";
                    }
                });
                getStyleColors(stylesString)
            }
            // else getStyleColors("ALL")
            else {
                if (styleList.length > 0) {
                    setColorStyle("")
                    setTableLoadDone(true)
                }
            }
        }

    }, [rejectionData]);

    useEffect(() => {
        if (rejectionList.length > 0 && styleList.length > 0) {
            setTableLoadDone(true)

        }
    }, [colorStyle]);

    const getStyleColors = (stylesString) => {

        getDb2StyleColor(selectedClient, stylesString, dbInfo)
            .then((response) => {
                // if (colorStyle) {
                //     setColorStyle(oldArray => [...oldArray, ...response]);
                // } else setColorStyle(response);
                setColorStyle(response)
            })
            .catch((error) => {
                console.log(error);
            });
    };

    let clientList = clientData ? clientData.map(ele => {
        return ele.CLIENT_NAME;
    }) : []

    clientList = clientList.filter(
        (element, i) => i === clientList.indexOf(element)
    )

    const handleChange1 = (event) => {
        let val = event.target.value;
        setClientName(val);
        setStyleList([]);
        setRetailerList(clientData.filter(x => x.CLIENT_NAME === val).map(x => x.RETAILER_NAME))
        setSelectedClient(clientData.filter(x => x.CLIENT_NAME === val)[0].CLIENT)
        setRetailerName("");
        let db = clientData.filter(x => x.CLIENT_NAME === val).map(x => x.DB_NAME)[0]
        let host = clientData.filter(x => x.CLIENT_NAME === val).map(x => x.SERVER_IP)[0]
        setdbInfo([db, host])
    };

    const handleChange2 = (event) => {
        let val = event.target ? event.target.value : event;
        // setRetailerName(val);
        let tpid = clientData.filter(x => x.RETAILER_NAME === val)[0].TRADE_PARTNER_ID
        getRejection(selectedClient, tpid)
            .then((rej) => {
                setRejectionData(rej);
            })
            .catch((error) => {
                console.log(error);
            });

        setRetailerName(val);
        setTableLoadDone(false)

    };

    const allowedPageSizes = [10, 25, 50, 100];

    let rejectionList = rejectionData.length > 0 ? rejectionData : [];

    let rdLookup = rejectionList.length > 0 ? rejectionList.map(d => d.DEPARTMENT).filter((val, index, self) => self.indexOf(val) === index).sort() : []
    let reasonLookup = rejectionList.length > 0 ? rejectionList.map(d => d.REJECTION_CAUSE).filter((val, index, self) => self.indexOf(val) === index).sort() : []


    const handleSubmit = () => {
        if (Object.keys(updatedRows).length !== 0) {
            setIsLoading(true);
            submit(updatedRows, user.nickname, new Date())
                .then((response) => {
                    if (response.message) {
                        alert(`Submit was unsuccessful. Reason: ${response.message}`);
                        setIsLoading(false);
                    }
                    else {
                        handleChange2(retailerName)
                        setIsLoading(false);
                        alert("Submit successful!");
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const calculateSummary = (column) => {
        // let totalQS =0, totalQA =0
        if (column === 'QS') {
            return rejectionList.reduce((acc, item) => acc + item.QS, 0).toLocaleString()
        } else
            return rejectionList.reduce((acc, item) => acc + item.QA, 0).toLocaleString();
    };

    const cellPrepared = (e) => {
        if (e.rowType === "filter") {
            e.cellElement.style.fontWeight = "bold"
            e.cellElement.style.color = '#333';
            if (e.column.dataField === "QS") {
                e.cellElement.innerHTML = calculateSummary('QS');
            }
            if (e.column.dataField === "QA") {
                e.cellElement.innerHTML = calculateSummary('QA');
            }
        }
        if (e.rowType === 'header') {
            e.cellElement.style.fontWeight = 'bold';
            e.cellElement.style.color = '#333';
        }
    }

    const rejectionsOnRowPrepared = (e) => {
        if (e.data && clientData.filter(x => x.RETAILER_NAME === retailerName)[0].TRADE_PARTNER_ID === e.data.TRADE_PARTNER_ID) {
            let key = [e.data.CLIENT, e.data.TRADE_PARTNER_ID, e.data.SRC_STYLE, e.data.SRC_COLOR, e.data.SRC_SIZE, e.data.REJECTION_CAUSE];
            if (e.cells.length > 9) {
                // if (![null, ""].includes(e.data.stm_style) && ((updatedRows.hasOwnProperty(key) && updatedRows[key].stm_nrf_color) || ![null, ""].includes(e.data.stm_nrf_color)) && (updatedRows.hasOwnProperty(key) && updatedRows[key].stm_size)) {
                if (updatedRows.hasOwnProperty(key) && updatedRows[key].STM_SIZE) {
                    e.rowElement.style.backgroundColor = "rgb(193,255,193)"
                    if (updatedRows[key].PROCESS_FLAG === 'N') {
                        let curData = JSON.parse(JSON.stringify(updatedRows[key]))
                        curData.PROCESS_FLAG = 'R';
                        curData.STATUS = 1;

                        updatedRows[key] = curData;
                        dataGridRef.current.instance.repaintRows(e.rowIndex);
                    }
                }
            }
            else {
                // if (![null, ""].includes(e.data.stm_style) && (updatedRows.hasOwnProperty(key) && updatedRows[key].stm_nrf_color)) {
                if (updatedRows.hasOwnProperty(key) && updatedRows[key].STM_NRF_COLOR) {
                    e.rowElement.style.backgroundColor = "rgb(193,255,193)"
                    if (updatedRows[key].PROCESS_FLAG === 'N') {
                        let curData = JSON.parse(JSON.stringify(updatedRows[key]))
                        curData.PROCESS_FLAG = 'R';
                        curData.STATUS = 1;

                        updatedRows[key] = curData;
                        dataGridRef.current.instance.repaintRows(e.rowIndex);
                    }
                }
            }

            if ((e.data.PROCESS_FLAG === 'P' && !updatedRows.hasOwnProperty(key)) || (updatedRows.hasOwnProperty(key) && updatedRows[key].PROCESS_FLAG === 'P')) {
                e.rowElement.style.backgroundColor = "rgb(255,255,210)"
            }

            if (updatedRows.hasOwnProperty(key) && updatedRows[key].PROCESS_FLAG === 'D') {
                e.rowElement.style.backgroundColor = "rgb(250,145,150)"
            }
        }
    }

    const cancelBtn = () => {
        showStylePage.clear()
        if (dataGridRef.current) {
            dataGridRef.current.instance.option("dataSource", rejectionList);
            dataGridRef.current.instance.columnOption('stm_style', 'width', 'auto');
            dataGridRef.current.instance.repaintRows(0);

        }

    }

    const selectedStyleSave = (style, rowIndex, container) => {
        if (container) {
            // let curRow = JSON.parse(JSON.stringify(container.data));
            let data = container.data;
            let key = [data.CLIENT, data.TRADE_PARTNER_ID, data.SRC_STYLE, data.SRC_COLOR, data.SRC_SIZE, data.REJECTION_CAUSE];
            let curRow = key in updatedRows ? JSON.parse(JSON.stringify(updatedRows[key])) : JSON.parse(JSON.stringify(data));
            curRow.STM_STYLE = style;
            updatedRows[key] = curRow;

            showStylePage.clear()
            container.component.cellValue(rowIndex, "Updated Style", style);
            getColorList(selectedClient, style, container)

            if (!updatedRows[key].locked) {
                for (let i = 0; i < container.component.getVisibleRows().length; i++) {
                    let otherRow = container.component.getVisibleRows()[i].data;
                    let key = [otherRow.CLIENT, otherRow.TRADE_PARTNER_ID, otherRow.SRC_STYLE, otherRow.SRC_COLOR, otherRow.SRC_SIZE, otherRow.REJECTION_CAUSE];
                    if (rowIndex !== i && otherRow.SRC_STYLE === container.data.SRC_STYLE && !(key in updatedColorOptions) && (!(key in updatedRows) || (key in updatedRows && !updatedRows[key].locked))) {
                        let row = JSON.parse(JSON.stringify(otherRow));
                        row.SRC_STYLE = style;
                        updatedRows[key] = row;
                        getColorList(selectedClient, style, container.component.getVisibleRows()[i])

                    }
                }
            }
        }
    }

    const getColorList = (client, style, container) => {
        let data = container.data;
        let key = [data.CLIENT, data.TRADE_PARTNER_ID, data.SRC_STYLE, data.SRC_COLOR, data.SRC_SIZE, data.REJECTION_CAUSE];
        let curRow = key in updatedRows ? JSON.parse(JSON.stringify(updatedRows[key])) : JSON.parse(JSON.stringify(data));

        curRow.STM_STYLE = style;
        updatedRows[key] = curRow;
        updatedColorOptions[key] = null;
        // let stylesString = `'${style}'`;
        let stylesString = style

        getDb2StyleColor(client, stylesString, dbInfo)
            .then((response) => {
                let colors = []
                response.forEach(element => {
                    if (element.NRF_COLOR_CODE.length > 0) {
                        // colors.push(element.COLOR_DESC + ' - ' + element.NRF_COLOR_CODE);
                        colors.push({
                            label: element.COLOR_DESC + ' - ' + element.NRF_COLOR_CODE,
                            value: element.NRF_COLOR_CODE
                        });
                    } else {
                        // colors.push(element.COLOR_DESC);
                        colors.push({
                            label: element.COLOR_DESC,
                            value: element.COLOR_DESC
                        });
                    }
                });

                updatedColorOptions[key] = colors;

                // const findColor = colors.includes(container.data.SRC_COLOR);
                const findColor = colors.find(color => color.label === container.data.SRC_COLOR);
                if (findColor) {
                    let curRow = key in updatedRows ? JSON.parse(JSON.stringify(updatedRows[key])) : JSON.parse(JSON.stringify(container.data));
                    // curRow.STM_NRF_COLOR = container.data.SRC_COLOR
                    curRow.STM_NRF_COLOR = findColor
                    updatedRows[key] = curRow;
                }

                if (dataGridRef.current) {
                    dataGridRef.current.instance.option("dataSource", rejectionList);
                    dataGridRef.current.instance.repaintRows(container.rowIndex);
                    dataGridRef.current.instance.columnOption('stm_style', 'width', 'auto');
                }

            })
    }

    const assignRejectionColor = (container, options) => {
        if (options && clientData.filter(x => x.RETAILER_NAME === retailerName)[0].TRADE_PARTNER_ID === options.data.TRADE_PARTNER_ID) {

            let key = [options.data.CLIENT, options.data.TRADE_PARTNER_ID, options.data.SRC_STYLE, options.data.SRC_COLOR, options.data.SRC_SIZE, options.data.REJECTION_CAUSE];
            $("<div>").append(options.value).appendTo(container);
            if (options.values.length > 9) {
                if (options.columnIndex === 1) {
                    if (([null, ""].includes(options.values[options.columnIndex + 4]) && !(key in updatedRows)) || (key in updatedRows && updatedRows[key].STM_STYLE === null)) {
                        $(container).attr("class", "rejected_style");
                    }
                }
                else if (options.columnIndex === 2) {
                    if ((([null, ""].includes(options.values[options.columnIndex + 4]) || Array.isArray(options.values[options.columnIndex + 4])) && !(key in updatedRows)) || (key in updatedRows && updatedRows[key].STM_NRF_COLOR === null)) {
                        $(container).attr("class", "rejected_style");
                    }
                }
                else {
                    if ((([null, ""].includes(options.values[options.columnIndex + 4]) || Array.isArray(options.values[options.columnIndex + 4])) && !(key in updatedRows)) || (key in updatedRows && updatedRows[key].STM_SIZE === null)) {
                        $(container).attr("class", "rejected_style");
                    }
                }
            }
            else {
                if (options.columnIndex === 1) {
                    if (([null, ""].includes(options.values[options.columnIndex + 3]) && !(key in updatedRows)) || (key in updatedRows && updatedRows[key].STM_STYLE === null)) {
                        $(container).attr("class", "rejected_style");
                    }
                }
                else if (options.columnIndex === 2) {
                    if ((([null, ""].includes(options.values[options.columnIndex + 3]) || Array.isArray(options.values[options.columnIndex + 3])) && !(key in updatedRows)) || (key in updatedRows && updatedRows[key].STM_NRF_COLOR === null)) {
                        $(container).attr("class", "rejected_style");
                    }
                }
            }
        }
    }

    function onToolbarPreparing(e) {      // hide all undo and save buttons above the table
        e.toolbarOptions.visible = false;
    }
    const selectStyleBtn = (e, val) => {
        showStylePage.clear();
        showStylePage.add(e.rowIndex);
        dataGridRef.current.instance.repaintRows();
    };

    const selectSize = (e, colorVal) => {

        let data = e.data
        let key = [data.CLIENT, data.TRADE_PARTNER_ID, data.SRC_STYLE, data.SRC_COLOR, data.SRC_SIZE, data.REJECTION_CAUSE];
        let style = key in updatedRows && updatedRows[key].STM_STYLE ? updatedRows[key].STM_STYLE : data.STM_STYLE;
        let color = key in updatedRows && updatedRows[key].STM_NRF_COLOR ? updatedRows[key].STM_NRF_COLOR.label.split("-")[0] : data.STM_NRF_COLOR.split("-")[0];
        if (colorVal) {
            // color = colorVal.split("-")[0]
            color = colorVal.substr(0, colorVal.lastIndexOf("-")).trim();
        }

        updatedSizeOptions[key] = null;

        getDb2StyleColorSize(selectedClient, style, color, dbInfo)
            .then((response) => {
                let sizes = []
                response.forEach(element => {
                    sizes.push(element.SIZE);
                });
                sizes.sort()

                updatedSizeOptions[key] = sizes;
                const findSize = sizes.includes(e.data.SRC_SIZE);
                if (findSize) {

                    let curRow = key in updatedRows ? JSON.parse(JSON.stringify(updatedRows[key])) : JSON.parse(JSON.stringify(e.data));
                    curRow.STM_SIZE = e.data.SRC_SIZE
                    curRow.PROCESS_FLAG = 'N'

                    updatedRows[key] = curRow;
                }
                dataGridRef.current.instance.repaintRows(e.rowIndex);
            })
            .catch((error) => {
                console.log(error);
            });
    }


    const selectColor = (e) => {
        let data = e.data
        let key = [data.CLIENT, data.TRADE_PARTNER_ID, data.SRC_STYLE, data.SRC_COLOR, data.SRC_SIZE, data.REJECTION_CAUSE];
        let style = key in updatedRows && updatedRows[key].STM_STYLE ? updatedRows[key].STM_STYLE : data.STM_STYLE
        updatedColorOptions[key] = null;

        let colorStyleCombo = []
        if (colorStyle) {
            let test1 = colorStyle.filter(x => {
                return x.STYLE === style
            })

            test1.forEach(element => {
                if (element.NRF_COLOR_CODE && element.NRF_COLOR_CODE.length > 0) {
                    // colorStyleCombo.push(element.COLOR_DESC + ' - ' + element.NRF_COLOR_CODE);
                    colorStyleCombo.push({
                        label: element.COLOR_DESC + ' - ' + element.NRF_COLOR_CODE,
                        value: element.NRF_COLOR_CODE
                    });
                } else if (element.COLOR_DESC) {
                    // colorStyleCombo.push(element.COLOR_DESC);
                    colorStyleCombo.push({
                        label: element.COLOR_DESC,
                        value: element.COLOR_DESC
                    });
                }
            });
        }
        updatedColorOptions[key] = colorStyleCombo;
        setLoad1(true);

        // const findColor = colorStyleCombo.includes(e.data.SRC_COLOR);
        const findColor = colorStyleCombo.find(color => color.label === e.data.SRC_COLOR);
        if (findColor) {
            let curRow = key in updatedRows ? JSON.parse(JSON.stringify(updatedRows[key])) : JSON.parse(JSON.stringify(e.data));
            // curRow.STM_NRF_COLOR = e.data.SRC_COLOR
            curRow.STM_NRF_COLOR = findColor

            updatedRows[key] = curRow;
        }

        dataGridRef.current.instance.repaintRows(e.rowIndex);

    }

    const colorChange = (value, data, loadIndex, e) => {
        let key = [data.CLIENT, data.TRADE_PARTNER_ID, data.SRC_STYLE, data.SRC_COLOR, data.SRC_SIZE, data.REJECTION_CAUSE];
        let curRow = key in updatedRows ? JSON.parse(JSON.stringify(updatedRows[key])) : JSON.parse(JSON.stringify(data));
        curRow.STM_NRF_COLOR = value
        if (e.values.length === 9) {
            curRow.PROCESS_FLAG = 'N'
        }
        e.component.cellValue(e.rowIndex, "Updated Color", value.label);
        updatedRows[key] = curRow;
        if (e.values.length > 9) {
            selectSize(e, value.label)
        }
        else {
            dataGridRef.current.instance.repaintRows(loadIndex);
        }

        for (let i = 0; i < e.component.getVisibleRows().length; i++) {
            let otherRow = e.component.getVisibleRows()[i].data;
            let key = [otherRow.CLIENT, otherRow.TRADE_PARTNER_ID, otherRow.SRC_STYLE, otherRow.SRC_COLOR, otherRow.SRC_SIZE, otherRow.REJECTION_CAUSE];
            if (i !== loadIndex && otherRow.SRC_STYLE === e.data.SRC_STYLE && otherRow.SRC_COLOR === e.data.SRC_COLOR && !(key in updatedSizeOptions)) {
                let row = key in updatedRows ? updatedRows[key] : JSON.parse(JSON.stringify(otherRow));
                row.STM_NRF_COLOR = value;
                updatedRows[key] = row;
                if (e.values.length > 9) {
                    selectSize(e.component.getVisibleRows()[i], value.label)
                }
                else {
                    dataGridRef.current.instance.repaintRows(i);
                }
            }
        }

    };

    const sizeChange = (value, data, loadIndex, rowIndex, e) => {
        let key = [data.CLIENT, data.TRADE_PARTNER_ID, data.SRC_STYLE, data.SRC_COLOR, data.SRC_SIZE, data.REJECTION_CAUSE];

        let curRow = key in updatedRows ? JSON.parse(JSON.stringify(updatedRows[key])) : JSON.parse(JSON.stringify(data));
        curRow.STM_SIZE = value
        curRow.PROCESS_FLAG = 'N'

        updatedRows[key] = curRow;
        dataGridRef.current.instance.repaintRows(rowIndex);

        for (let i = 0; i < e.component.getVisibleRows().length; i++) {
            let otherRow = e.component.getVisibleRows()[i].data;
            let key = [otherRow.CLIENT, otherRow.TRADE_PARTNER_ID, otherRow.SRC_STYLE, otherRow.SRC_COLOR, otherRow.SRC_SIZE, otherRow.REJECTION_CAUSE];
            if (i !== loadIndex && otherRow.SRC_STYLE === e.data.SRC_STYLE && otherRow.SRC_SIZE === e.data.SRC_SIZE && key in updatedRows && !updatedRows[key].STM_SIZE) {
                let row = key in updatedRows ? updatedRows[key] : JSON.parse(JSON.stringify(otherRow));
                row.SRC_STYLE = value;

                row.process_flag = 'N'
                updatedRows[key] = row;
                dataGridRef.current.instance.repaintRows(i);
            }
        }

    };



    const selectStyleIcon = (event) => {
        event.cellElement.innerHTML = "Searching..."

        if (!Array.isArray(event)) {

            const searcher = new Fuse(styleList, {
                caseSensitive: false,
                includeScore: false,
                shouldSort: true,
                threshold: 0.25,
                location: 0,
                distance: 400,
                maxPatternLength: 15,
                //keys: ['style']
            });
            const result = searcher.search(event.key.SRC_STYLE);
            let data = event.data
            let key = [data.CLIENT, data.TRADE_PARTNER_ID, data.SRC_STYLE, data.SRC_COLOR, data.SRC_SIZE, data.REJECTION_CAUSE];


            if (result.length > 0) {

                let foundStyle = result[0].item;
                let curRow = key in updatedRows ? JSON.parse(JSON.stringify(updatedRows[key])) : JSON.parse(JSON.stringify(data));
                curRow.STM_STYLE = foundStyle

                updatedRows[key] = curRow;

                event.component.cellValue(event.rowIndex, "Updated Style", foundStyle);
                getColorList(selectedClient, foundStyle, event)

                if (!updatedRows[key].locked) {
                    for (let i = 0; i < event.component.getVisibleRows().length; i++) {
                        let otherRow = event.component.getVisibleRows()[i].data;
                        let key = [otherRow.CLIENT, otherRow.TRADE_PARTNER_ID, otherRow.SRC_STYLE, otherRow.SRC_COLOR, otherRow.SRC_SIZE, otherRow.REJECTION_CAUSE];
                        if (event.rowIndex !== i && otherRow.SRC_STYLE === event.data.SRC_STYLE && !(key in updatedColorOptions) && (!(key in updatedRows) || (key in updatedRows && !updatedRows[key].locked))) {
                            let row = JSON.parse(JSON.stringify(otherRow));
                            row.STM_STYLE = foundStyle;
                            updatedRows[key] = row;
                            getColorList(selectedClient, foundStyle, event.component.getVisibleRows()[i])

                        }
                    }
                }
            } else {
                if (dataGridRef.current) {
                    dataGridRef.current.instance.repaintRows(event.rowIndex);
                }
                alert("No style found!")
            }

        }

    };

    const tableReady = rejectionList.length > 0 && clientData.length > 0 && retailerName.length > 0 ? rejectionList[0].TRADE_PARTNER_ID === clientData.filter(x => x.RETAILER_NAME === retailerName)[0].TRADE_PARTNER_ID : false;



    const changeColumnWidth = (columnDataField, newWidth) => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.option("dataSource", rejectionList);
            //dataGridRef.current.instance.repaintRows(0);

            dataGridRef.current.instance.columnOption('stm_style', 'width', '350');
            selectStyleBtn(columnDataField, 'btnClick')
        }
    };

    let currentPage = 0;

    const onContentReady = (e) => {
        if (dataGridRef.current && dataGridRef.current.instance.pageIndex() !== currentPage) {
            showStylePage.clear()
            dataGridRef.current.instance.option("dataSource", rejectionList);
            dataGridRef.current.instance.columnOption('stm_style', 'width', '');
            dataGridRef.current.instance.repaintRows(0);
            currentPage = e.component.pageIndex()
        }

    }

    return (
        <Container maxWidth="xl" p={1}>
            {noRejections ?
                <div className={classes.root} >
                    <h1>No new available rejections to be resolved</h1>
                </div> :
                <SelectClientRetailer
                    rejectionList={rejectionList}
                    classes={classes}
                    clientList={clientList}
                    retailerList={retailerList}
                    clientName={clientName}
                    retailerName={retailerName}
                    handleChange1={(e) => handleChange1(e)}
                    handleChange2={(e) => handleChange2(e)}
                />
            }

            <Button style={{ position: 'absolute', top: 80, right: 30 }}
                className="helpcenter"
                variant="contained"
                color="inherit"
                // href="https://skypad.zendesk.com/hc/en-us/categories/360001948172-SKYALIGN"
                onClick={() => window.open("https://skypad.zendesk.com/hc/en-us/categories/360001948172-SKYALIGN", "_blank")}
            >
                Help Center
            </Button>

            {rejectionList.length > 0 && retailerName.length > 0 && tableReady ?
                <RejectionTable
                    rejectionList={rejectionList}
                    updatedRows={updatedRows}
                    updatedColorOptions={updatedColorOptions}
                    updatedSizeOptions={updatedSizeOptions}
                    dataGridRef={dataGridRef}
                    onToolbarPreparing={onToolbarPreparing}
                    rejectionsOnRowPrepared={rejectionsOnRowPrepared}
                    allowedPageSizes={allowedPageSizes}
                    rdLookup={rdLookup}
                    reasonLookup={reasonLookup}
                    assignRejectionColor={assignRejectionColor}
                    showStylePage={showStylePage}
                    selectedClient={selectedClient}
                    classes={classes}
                    selectStyleBtn={(e, val) => selectStyleBtn(e, val)}
                    cancelBtn={() => cancelBtn()}
                    selectStyleIcon={(e) => selectStyleIcon(e)}
                    selectColor={selectColor}
                    selectSize={selectSize}
                    sizeChange={sizeChange}
                    colorChange={colorChange}
                    selectedStyleSave={selectedStyleSave}
                    cellPrepared={cellPrepared}
                    changeColumnWidth={changeColumnWidth}
                    load1={load1}
                    tableLoadDone={tableLoadDone}
                    onContentReady={onContentReady}
                    dbInfo={dbInfo}
                    styleList={styleList}
                />
                : 
                retailerName.length > 0 ? <Typography variant="h6" align="center">No data to be displayed.</Typography> : <></>
            }

            {
                rejectionList.length > 0 && retailerName.length > 0 && tableReady ?
                    <Button style={{ marginLeft: 10, marginTop: 10 }}
                        className="showrejections"
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Submit Updates
                    </Button>
                    : <></>
            }

        </Container >
    );
}
