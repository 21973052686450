/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    useMemo} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axiosInt from '../apis/configs/AxiosConfig';

export const Db2ApiContext = React.createContext();

export const Db2ApiProvider = ({ children }) => {

    const { getAccessTokenSilently } = useAuth0();

    const getAPI =
        async (config) => {
            let access_token_hook = await getAccessTokenSilently();
            config.headers = {
                authorization: `Bearer ${access_token_hook}`
            }
            return new Promise((resolve, reject) => {
                axiosInt.request(config)
                    .then((response) => {
                        resolve(response.data);
                    }).catch(ex => {
                    })
            })
        };

    const getClient = async (user, userClient) => {
        let config = {
            url: 'db2GetClientList',
            method: "GET",
            params: {user: user, userClient: userClient},
        }
        let response = await getAPI(config);
        return response
    }

    const submit = async function (rows, user, date) {
        let config = {
            url: `/db2Submit`,
            method: "POST",
            data: {rows: rows, date: date, user: user },
        }
        let response = await getAPI(config);
        return response
    }

    const getDb2Style = async function (selectedClient, dbInfo) {
        let config = {
            url: 'db2Styles',
            method: "GET",
            params: { client: selectedClient, db:dbInfo}
        }
        let response = await getAPI(config);
        return response
    }
    const getDb2StyleColor = async function (selectedClient,stylesString, dbInfo) {
        let config = {
            url: 'db2StyleColors',
            method: "POST",
            data: { client: selectedClient,style: stylesString, db: dbInfo }
        }
        let response = await getAPI(config);
        return response
    }
    const getDb2StyleColorSize = async function (selectedClient, style, color, dbInfo) {
        let config = {
            url: 'db2StyleColorSizes',
            method: "GET",
            params: { client: selectedClient, style: style, colorDesc: color, db: dbInfo }
        }
        let response = await getAPI(config);
        return response
    }

    const getRejection = async function (selectedClient, tpid) {
        let config = {
            url: 'db2Rejections',
            method: "GET",
            params: { client: selectedClient, trade_partner_id: tpid }
        }
        let response = await getAPI(config);
        return response
    }
    const getHiddenRejection = async function (selectedClient, tpid) {
        let config = {
            url: 'db2HiddenRejections',
            method: "GET",
            params: { client: selectedClient, trade_partner_id: tpid }
        }
        let response = await getAPI(config);
        return response
    }

    const contextValue = useMemo(() => {
        return {
            getClient,
            submit,
            getDb2Style,
            getDb2StyleColor,
            getDb2StyleColorSize,
            getRejection,
            getHiddenRejection
        };
    }, [
        getClient,
        submit,
        getDb2Style,
        getDb2StyleColor,
        getDb2StyleColorSize,
        getRejection,
        getHiddenRejection
    ]);

    return (
        <Db2ApiContext.Provider value={contextValue}>
            {children}
        </Db2ApiContext.Provider>
    );
};