import React from 'react';
import { Grid, MenuItem, Select, Box } from "@material-ui/core";

const SelectClientRetailer = ({ rejectionList, classes, clientList, retailerList, clientName, retailerName, handleChange1, handleChange2 }) => {
    return (

        <Box display="flex" sx={{ background: 'rgb(250, 250, 250)', margin: '10px 0' }}>
            <Box p={1}>
                <Grid container alignItems="flex-end">
                    <Grid item style={{ lineHeight: 2 }}>
                        <b>Selected client:</b>
                    </Grid>
                    <Grid item>
                        <Select
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            style={{ minWidth: '100px', marginLeft: '10px' }}
                            onChange={handleChange1}
                            value={clientName}
                        >
                            {clientList.sort().map((eachClient) => (
                                <MenuItem value={eachClient}>{eachClient}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>

            </Box>

            <Box p={1} flexGrow={1}>
                <Grid container spacing={1} alignItems="flex-end">
                    <Grid item style={{ lineHeight: 2 }}>
                        <b>Selected retailer:</b>
                    </Grid>
                    <Grid item>
                        <Select
                            labelId="demo-controlled-open-select-label1"
                            id="demo-controlled-open-select1"
                            style={{ minWidth: '120px' }}
                            onChange={handleChange2}
                            value={retailerName}
                        >
                            {retailerList.length > 0 ? retailerList.sort().map((eachRetailer) => (
                                <MenuItem value={eachRetailer}>{eachRetailer}</MenuItem>
                            )) : <MenuItem></MenuItem>}
                        </Select>
                    </Grid>
                </Grid>
            </Box>
            <Box p={1}>

            </Box>

        </Box>

    );
};

export default SelectClientRetailer;