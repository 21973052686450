import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { Auth0Provider } from '@auth0/auth0-react';
import { VerticaApiProvider } from './contexts/verticaApiContext';
import { Db2ApiProvider } from './contexts/db2ApiContext';


ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain='skypad.auth0.com'
      clientId="FcWkWuPgtIsJUS34wm1UiEosmRhVU4kp"
      redirectUri={window.location.origin}
      audience='https://www.skypad-node-api.com'
      scope="openid profile email"
      responseType="token id_token"
      state="SkyAlign"
    >
      <VerticaApiProvider>
        <Db2ApiProvider>
          <App />
        </Db2ApiProvider>
      </VerticaApiProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
