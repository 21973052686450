import React from 'react';
import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    btnHover: {
        backgroundColor:'none',
        fontStyle: 'italic',
        // fontSize:'0.8rem',
        textDecoration: 'none',
        borderBottom: 'dashed 1px #428bca',
        '&:hover': {
            textDecoration: 'none'
        },
        '& .goodSelection': {
            color: 'green'
        },
        '& .badSelection': {
            color: 'red',
        }

    },
}));

const ButtonStyle = (props) => {
    const classes = useStyles();

    const selectStyleBtn = (e) => {
        props.selectStyleBtn();
    }

    return (
        <Link component="button" variant="body2" onClick={selectStyleBtn} className={classes.btnHover} disableElevation>
            <span className={props.className}>{props.btnName}</span>
        </Link>
    )
};

export default ButtonStyle;
