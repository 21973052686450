import React, { useEffect } from 'react';
import { TextField, Box, Card, Typography } from "@material-ui/core";
import DataGrid, {
    Pager, Paging, Editing, Column, FilterRow, Lookup
} from 'devextreme-react/data-grid';
import { CheckBox } from 'devextreme-react/check-box';
import ButtonStyle from '../style/ButtonStyle';
import {
    faWandMagicSparkles
} from '@fortawesome/free-solid-svg-icons';
import '../style/customStyle.css';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from 'devextreme-react/tooltip';
import { Button } from 'devextreme-react/button';
import StyleBox from './styleAutoSelect';
import { Autocomplete } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CircularProgress from '@material-ui/core/CircularProgress';


const RejectionTable = ({
    rejectionList,
    updatedRows,
    updatedColorOptions,
    updatedSizeOptions,
    dataGridRef,
    onToolbarPreparing,
    rejectionsOnRowPrepared,
    allowedPageSizes,
    rdLookup,
    reasonLookup,
    assignRejectionColor,
    showStylePage,
    rowIndex,
    selectedClient,
    classes,
    cancelBtn,
    selectStyleIcon,
    selectColor,
    selectSize,
    sizeChange,
    colorChange,
    selectedStyleSave,
    cellPrepared,
    changeColumnWidth,
    tableLoadDone,
    onContentReady,
    style,
    upc
}) => {

    const colorCellRender = (options) => {

        return options.key.stm_nrf_color !== null ?
            <Typography variant='div'>{options.key.stm_nrf_color}</Typography>
            :
            updatedColorOptions[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]] ?
                <>
                    <Autocomplete
                        className={classes.selectColor}
                        id="custom-input-demo"
                        options={updatedColorOptions[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]]}
                        getOptionLabel={(option) => option.label}
                        getOptionSelected={(option, value) => option.label === value.label}
                        value={updatedRows[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]] ? updatedRows[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]].stm_nrf_color : null}
                        onChange={(_, _metric) => {
                            if (_metric !== null) {
                                colorChange(_metric, options.data, options.row.loadIndex, options)
                            }
                        }}
                        size="small"
                        disableClearable
                        renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Select a Color" />}
                        ListboxProps={{
                            style: {
                              maxHeight: '300px',
                              overflow: 'auto',   
                            },
                        }}
                        ListboxComponent={(props) => (
                            <ul {...props} className="autoCompleteField">
                                {React.Children.map(props.children, (child) => {
                                    return React.cloneElement(child, {
                                        ...child.props,
                                        className: 'autoCompleteList',
                                    });
                                })}
                            </ul>
                        )}
                    />
                </>
                :
                (updatedColorOptions[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]] === null) ?
                    <CircularProgress className={classes.spinnerIcon} />
                    :
                    options.data.stm_style !== null ?
                        selectColor(options)
                        :
                        ''
    }

    const cellSizeRender = (options) => {
        return options.key.stm_size !== null ?
            <Typography variant='div'>{options.key.stm_size}</Typography>
            :
            updatedSizeOptions[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]] ?

                <Autocomplete
                    className={classes.selectColor}
                    id="custom-input-demo"
                    options={updatedSizeOptions[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]]}
                    value={updatedRows[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]] ? updatedRows[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]].stm_size : null}
                    onChange={(_, _metric) => {
                        if (_metric !== null) {
                            sizeChange(_metric, options.data, options.row.loadIndex, options.rowIndex, options)

                        }
                    }}
                    size="small"
                    disableClearable
                    renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Select a Size" />}
                    ListboxComponent={(props) => (
                        <ul {...props} className="autoCompleteField">
                            {React.Children.map(props.children, (child) => {
                                return React.cloneElement(child, {
                                    ...child.props,
                                    className: 'autoCompleteList',
                                });
                            })}
                        </ul>
                    )}
                />
                :
                (updatedSizeOptions[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]] === null) ?
                    <CircularProgress className={classes.spinnerIcon} />
                    :
                    (options.data.stm_nrf_color !== null && !([options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause] in updatedSizeOptions)) ?
                        selectSize(options)
                        : ''
    }



    return (
        <Card style={{ padding: "6px" }}>
            {tableLoadDone ?
                <DataGrid className="sTable"
                    columnAutoWidth={true}
                    allowColumnResizing={true}
                    id="currentRejections"
                    ref={dataGridRef}
                    dataSource={rejectionList || []}
                    showBorders={true}
                    showRowLines={true}
                    showColumnLines={false}
                    showScrollbar={true}
                    onToolbarPreparing={onToolbarPreparing}
                    onRowPrepared={rejectionsOnRowPrepared}
                    onCellPrepared={cellPrepared}
                    onContentReady={onContentReady}
                    noDataText="No data"
                >
                    <FilterRow visible={true} />

                    <Paging
                        defaultPageSize={25}
                    />

                    <Pager
                        visible={true}
                        allowedPageSizes={allowedPageSizes}
                        showPageSizeSelector={true}
                        showNavigationButtons={true}
                    />

                    <Editing
                        mode="batch"
                        allowUpdating={true}
                        allowDeleting={true}
                        useIcons={true}
                    />

                    <Column dataField="department" caption="Retailer Dept" allowEditing={false} >
                        <Lookup dataSource={rdLookup} />
                    </Column>

                    <Column dataField="src_style" caption="Rejected Style" allowEditing={false} cellTemplate={assignRejectionColor} />

                    <Column dataField="src_color" caption="Rejected Color" allowEditing={false} cellTemplate={assignRejectionColor} />
                    {rejectionList && rejectionList[0].src_size !== "IGNORE" ?
                        <Column dataField="src_size" caption="Rejected Size" allowEditing={false} cellTemplate={assignRejectionColor} /> :
                        <></>
                    }

                    <Column dataField="rejection_cause" caption="Reason" allowEditing={false} >
                        <Lookup dataSource={reasonLookup} />
                    </Column>
                    <Column dataField="stm_style" caption="Updated Style" allowEditing={false}
                        allowFiltering={false} allowSorting={false}
                        cellRender={(container) => {

                            return <>
                                <Box display="flex" justifyContent="start" m={0} p={0} style={{ alignItems: 'center' }}>

                                    {
                                        showStylePage && showStylePage.has(container.rowIndex) ?
                                            <StyleBox options={container} selectedClient={selectedClient} style={style} upc={upc} cancelBtn={() => cancelBtn(container)} selectedStyleSave={(selectedStyle, upc_code) => selectedStyleSave(selectedStyle, upc_code, container.row.rowIndex, container)} />
                                            :

                                            container.key.stm_style && container.key.stm_nrf_color ?
                                                <Typography variant="div">{container.key.stm_style}</Typography>
                                                : container.key.stm_style ?
                                                    <>
                                                        <ButtonStyle selectStyleBtn={() => changeColumnWidth(container, 'btnClick')} btnName={container.value} className="goodSelection" />
                                                    </>
                                                    : [container.data.clientId, container.data.tpid, container.data.src_style, container.data.src_color, container.data.src_size, container.data.rejection_cause] in updatedRows && updatedRows[[container.data.clientId, container.data.tpid, container.data.src_style, container.data.src_color, container.data.src_size, container.data.rejection_cause]].stm_style ?
                                                        <>
                                                            <ButtonStyle selectStyleBtn={() => changeColumnWidth(container, 'btnClick')} btnName={updatedRows[[container.data.clientId, container.data.tpid, container.data.src_style, container.data.src_color, container.data.src_size, container.data.rejection_cause]].stm_style} className="goodSelection" />
                                                        </> :

                                                        <>
                                                            <ButtonStyle selectStyleBtn={() => changeColumnWidth(container, 'btnClick')} btnName="Select Style" className="badSelection" />

                                                            <IconButton p={0} onClick={() => selectStyleIcon(container, 'iconClick')} aria-label="create" className={classes.btnIconHover} disableElevation>
                                                                <FontAwesomeIcon icon={faWandMagicSparkles} />
                                                            </IconButton>

                                                            <CheckBox
                                                                iconSize={9}
                                                                hint='Lock Style'
                                                                value={[container.data.clientId, container.data.tpid, container.data.src_style, container.data.src_color, container.data.src_size, container.data.rejection_cause] in updatedRows && updatedRows[[container.data.clientId, container.data.tpid, container.data.src_style, container.data.src_color, container.data.src_size, container.data.rejection_cause]].locked ? true : false}
                                                                style={{ marginLeft: 8, border: '0.1px solid black' }}
                                                                onValueChange={(e) => {
                                                                    let key = [container.data.clientId, container.data.tpid, container.data.src_style, container.data.src_color, container.data.src_size, container.data.rejection_cause];

                                                                    if (key in updatedRows) {
                                                                        updatedRows[key].locked = !updatedRows[key].locked;
                                                                    }
                                                                    else {
                                                                        let curData = JSON.parse(JSON.stringify(container.data));
                                                                        curData.locked = true;
                                                                        updatedRows[key] = curData;
                                                                    }
                                                                    dataGridRef.current.instance.repaintRows(container.rowIndex);
                                                                }}
                                                            />
                                                        </>
                                    }
                                </Box>
                            </>;
                        }}
                    />

                    {selectedClient !== "LOR" ?
                        <Column dataField="stm_nrf_color" caption="Updated Color" allowEditing={false} allowFiltering={false} allowSorting={false}
                            cellRender={colorCellRender}
                        /> : <></>
                    }
                    {rejectionList && rejectionList[0].src_size !== "IGNORE" && selectedClient !== "LOR" ?
                        <Column dataField="stm_size" caption="Updated Size" allowEditing={false} allowFiltering={false} allowSorting={false}
                            cellRender={cellSizeRender}
                        /> :
                        <></>
                    }

                    <Column dataField="qs" caption="Sls U" allowEditing={false} allowFiltering={false} allowSorting={true} format="###,###,##0" />
                    <Column dataField="qa" caption="OH U" allowEditing={false} allowFiltering={false} allowSorting={true} format="###,###,##0" />

                    <Column
                        type="buttons"
                        cellRender={(options) => (
                            <div className="dx-data-grid-cell-value">
                                {[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause] in updatedRows && updatedRows[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]].process_flag === 'D' ? <Button
                                    hint="Cancel"
                                    icon="close"
                                    onClick={() => {
                                        let key = [options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause];
                                        let curData = key in updatedRows ? JSON.parse(JSON.stringify(updatedRows[key])) : JSON.parse(JSON.stringify(options.data));
                                        curData.process_flag = 'N';
                                        delete curData.status;

                                        updatedRows[key] = curData;
                                        dataGridRef.current.instance.repaintRows(options.rowIndex);
                                    }}
                                /> : <Button
                                    hint="Delete"
                                    icon="trash"
                                    onClick={() => {
                                        let key = [options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause];
                                        let curData = key in updatedRows ? JSON.parse(JSON.stringify(updatedRows[key])) : JSON.parse(JSON.stringify(options.data));
                                        curData.process_flag = 'D';
                                        curData.status = 1;

                                        updatedRows[key] = curData;
                                        dataGridRef.current.instance.repaintRows(options.rowIndex);
                                    }}
                                />}

                                <Button
                                    hint="Undo"
                                    icon="undo"
                                    onClick={() => {
                                        let key = [options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause];
                                        let curData = key in updatedRows ? JSON.parse(JSON.stringify(updatedRows[key])) : JSON.parse(JSON.stringify(options.data));
                                        curData.process_flag = curData.process_flag === 'P' ? 'N' : 'P';
                                        if ('status' in curData) {
                                            if (curData.status === 1) {
                                                curData.status = -1
                                            }
                                            else {
                                                delete curData.status;
                                            }
                                        }
                                        else {
                                            curData.status = -1;
                                        }

                                        updatedRows[key] = curData;
                                        dataGridRef.current.instance.repaintRows(options.rowIndex);
                                    }}
                                />
                                <Button
                                    icon="info"
                                    className={`info-button-${options.row.loadIndex}`}
                                />
                                <Tooltip
                                    target={`.info-button-${options.row.loadIndex}`}
                                    position="left"
                                    showEvent="mouseenter"
                                    hideEvent="mouseleave"
                                >
                                    <div>STM Style Desc: {options.data.style_desc}</div>
                                    <div>Week End: {options.data.week_ending_date}</div>
                                    <div>Product Desc: {options.data.product_desc}</div>
                                    <div>Filename: {options.data.file_name}</div>
                                </Tooltip>
                            </div>
                        )}
                    />

                </DataGrid>
                :
                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress className={classes.spinnerIcon} style={{ margin: '-4px' }} />
                    <Typography style={{ display: 'inline-block', margin: '0px 0px 0px 25px', fontSize: '14px' }} >Loading Colors...</Typography>
                </Box>
            }
        </Card>

    );
};

export default RejectionTable;