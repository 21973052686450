/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    useMemo
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axiosInt from '../apis/configs/AxiosConfig';

export const VerticaApiContext = React.createContext();

export const VerticaApiProvider = ({ children }) => {

    const { getAccessTokenSilently } = useAuth0();

    const getAPI =
        async (config) => {
            let access_token_hook = await getAccessTokenSilently();
            config.headers = {
                authorization: `Bearer ${access_token_hook}`
            }
            return new Promise((resolve, reject) => {
                axiosInt.request(config)
                    .then((response) => {
                        resolve(response.data);
                    }).catch(ex => {
                        reject(ex)
                    })
            })
        };

    const getClient = async (userClient) => {
        let config = {
            url: 'getClientList',
            method: "GET",
            params: { userClient: userClient },
        }
        let response = await getAPI(config);
        return response
    }

    const submit = async function (showHiddenRejectedTable, rows, user, date) {
        const newRows = {};
        const keys = Object.keys(rows);
        for (let i = 0; i < keys.length; i++) {
            newRows[i] = {
                clientId: rows[keys[i]].clientId,
                tpid: rows[keys[i]].tpid,
                src_style: rows[keys[i]].src_style,
                src_color: rows[keys[i]].src_color,
                src_size: rows[keys[i]].src_size,
                stm_style: rows[keys[i]].clientId !== "LOR" ? rows[keys[i]].stm_style : rows[keys[i]].stm_style ?  rows[keys[i]].stm_style.split(" - ")[0] : null,
                stm_nrf_color: rows[keys[i]].clientId !== "LOR" ? rows[keys[i]].stm_nrf_color : "N/A",
                stm_size: rows[keys[i]].clientId !== "LOR" ? rows[keys[i]].stm_size : "N/A",
                process_flag: rows[keys[i]].process_flag,
                stm_upc_code: rows[keys[i]].stm_upc_code,
                status: rows[keys[i]].status
            };
        }
        let config = {
            url: `/submit`,
            method: "POST",
            data: { showHiddenRejectedTable: showHiddenRejectedTable, rows: newRows, date: date, user: user },
        }
        let response = await getAPI(config);
        return response
    }

    const getVerticaStyle = async function (selectedClient, style) {
        let config = {
            url: 'verticaStyles',
            method: "GET",
            params: { client: selectedClient, style: style }
        }
        let response = await getAPI(config);
        return response
    }
    const getVerticaStyleColor = async function (selectedClient, stylesString) {
        let config = {
            url: 'verticalStyleColors',
            method: "POST",
            data: { client: selectedClient, style: stylesString }
        }
        let response = await getAPI(config);
        return response
    }
    const getVerticaStyleColorSize = async function (selectedClient, style, color) {
        let config = {
            url: 'verticalStyleColorSizes',
            method: "GET",
            params: { client: selectedClient, style: style, colorDesc: color }
        }
        let response = await getAPI(config);
        return response
    }

    const getRejection = async function (selectedClient, tpid) {
        let config = {
            url: 'rejections',
            method: "GET",
            params: { client: selectedClient, trade_partner_id: tpid }
        }
        let response = await getAPI(config);
        return response
    }
    const getHiddenRejection = async function (selectedClient, tpid) {
        let config = {
            url: 'hiddenRejections',
            method: "GET",
            params: { client: selectedClient, trade_partner_id: tpid }
        }
        let response = await getAPI(config);
        return response
    }

    const contextValue = useMemo(() => {
        return {
            getClient,
            submit,
            getVerticaStyle,
            getVerticaStyleColor,
            getVerticaStyleColorSize,
            getRejection,
            getHiddenRejection
        };
    }, [
        getClient,
        submit,
        getVerticaStyle,
        getVerticaStyleColor,
        getVerticaStyleColorSize,
        getRejection,
        getHiddenRejection
    ]);

    return (
        <VerticaApiContext.Provider value={contextValue}>
            {children}
        </VerticaApiContext.Provider>
    );
};