import React from "react";
import { Helmet } from "react-helmet";
import { Route, Router, Switch } from "react-router-dom";

import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import 'devextreme/dist/css/dx.light.css';
import "./App.css";
import Home from "./modules/Home";
import history from "./history";
import AccessDeniedPage from "./components/accessDenied";

const TITLE = "SkyAlign";


function App() {

  const {
    loginWithRedirect,
    user,
    isAuthenticated,
    isLoading,
    //logout
  } = useAuth0();

  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    if (isAuthenticated && !isLoading && !user['http://skypad_user/groups'].includes('Sky Internal') && !user['http://skypad_user/groups'].filter(x => x.includes('Rejections All')).length) {
      // alert('User does not have access!')
      setIsAuth(false);
    }
    else if (isAuthenticated && !isLoading) {
      setIsAuth(true);
    } else if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }
    // else {
    //   setIsAuth(false);
    // }
  }, [isAuthenticated, isLoading])

  return (
    !isAuth ? <AccessDeniedPage /> :
      (<div className="application">
        <Helmet>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
          />
          <title>{TITLE}</title>
        </Helmet>
        <div className="App">
          <Router history={history}>
            <Switch>
              <Route
                path="/"
                render={props => {
                  return <Home {...props} />;
                }}
              />
            </Switch>
          </Router>
        </div>
      </div>)
  );
}

export default App;