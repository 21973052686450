import React, { useState, useContext } from 'react';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { VerticaApiContext } from '../../contexts/verticaApiContext';

const useStyles = makeStyles((theme) => ({
  auto: {
    width: '100%',
    padding: '5px'
  },
  margin: {
    width: '10px',
    margin: '0',
    padding: '0',
    background: 'lightgray',
    height: '25px'
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const OPTIONS_LIMIT = 8;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

const StyleBox = (props) => {
  const [style, setStyle] = useState('');
  const [styleList, setStyleList] = useState([]);
  const [upcList, setUPCList] = useState({});
  const [searchValue, setSearchValue] = useState([])
  const [selectedStyle, setSelectedStyle] = useState('')
  const [styleNotExistText, setStyleNotExistText] = useState(false);
  const classes = useStyles();
  const { getVerticaStyle } = useContext(VerticaApiContext);

  const handleStyleChange = (e) => {
    if (e !== null && e !== 0) {
      setStyle(e.currentTarget.value);
    }
  };

  const checkStyleExist = (styleTyped) => {
    let findStyle = props.style.find(el => el === styleTyped);
    if (findStyle) {
      return true;
    } else {
      setStyleNotExistText(true);
    }
  }

  const saveBtn = () => {
    if (style !== 0) {
      setSelectedStyle(style.toUpperCase());
    } else setSelectedStyle(selectedStyle)

    // if (props.options.displayValue !== null) {
    //   //props.options.component.cellValue(props.options.row.rowIndex, "Updated Style", props.options.displayValue);
    //   props.selectedStyleSave(props.options.displayValue)
    // }

    if (style !== "" && style !== 0) {

      if (checkStyleExist(style.toUpperCase())) {
        //props.options.component.cellValue(props.options.row.rowIndex, "Updated Style", style.toUpperCase());
        //setStyle(selectedStyle);
        props.selectedStyleSave(style.toUpperCase())
      }
    }
    else if (selectedStyle !== "") {
      if (checkStyleExist(selectedStyle)) {
        //props.options.component.cellValue(props.options.row.rowIndex, "Updated Style", selectedStyle);
        const upc_code = props.upc[selectedStyle];
        props.selectedStyleSave(selectedStyle, upc_code)
      }
    }
  }

  const cancelBtn = () => {
    props.cancelBtn();
  }

  // const onChangeEvent = (event) => {
  //   setSearchValue(event.target.value)
  //   if (event.target.value.length > 0 && styleList.length === 0) {
  //     getVerticaStyle(props.selectedClient, event.target.value)
  //       .then((response) => {
  //         let styles = []
  //         let upc = {}
  //         response.forEach(element => {
  //           if (props.selectedClient === "LOR") {
  //             styles.push(element.style + " - " + element.upc_code);
  //             upc[element.style + " - " + element.upc_code] = element.upc_code;
  //           }
  //           else{
  //           styles.push(element.style);
  //           upc[element.style] = element.upc_code;
  //           }
  //         });
  //         setStyleList(styles)
  //         setUPCList(upc)
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  //   else if (event.target.value.length === 0) {
  //     setStyleList([])
  //     setUPCList({})
  //   }
  // }

  return (
    // <Box display="flex" justifyContent="start" m={0} p={0} bgcolor="background.paper">
    <>
      <Box style={{ paddingRight: '10px' }}>
        <Autocomplete
          filterOptions={filterOptions}
          id="custom-input-demo"
          options={props.style}
          // onKeyUp={onChangeEvent}
          value={selectedStyle ? selectedStyle : props.options.displayValue}
          onInputChange={handleStyleChange}
          onChange={(_, _metric) => {
            if (_metric !== null) {
              setSelectedStyle(_metric);
            }
          }}
          // noOptionsText={
          //   searchValue.length > 0 && style.length === 0
          //     ? "Searching for result..."
          //     : "No options"
          // }

          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <input type="text" {...params.inputProps} />
            </div>
          )}
          ListboxComponent={(props) => (
            <ul {...props} className="autoCompleteField">
              {React.Children.map(props.children, (child) => {
                return React.cloneElement(child, {
                  ...child.props,
                  className: 'autoCompleteList',
                });
              })}
            </ul>
          )}
        />
        {styleNotExistText && (
          <span>
            Style must exist
          </span>
        )}
      </Box>

      <Box style={{ paddingRight: '10px' }}>
        <Button size="small" onClick={saveBtn} className={classes.margin}>
          Save
        </Button>
      </Box>

      <Box style={{ paddingRight: '10px' }}>
        <Button size="small" onClick={cancelBtn} className={classes.margin} >
          Cancel
        </Button>
      </Box>

    </>

    // <Autocomplete
    //   dataSource={styleList}
    //   value={style}
    //   onValueChanged={handleStyleChange}
    //   placeholder="Type style..."
    // />
  );
};

export default StyleBox;

