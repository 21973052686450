import React from "react";
import StyleMasterAppBar from "../StyleMasterAppBar";
import { useAuth0 } from '@auth0/auth0-react';

export default function AccessDeniedPage(props) {

    const {
        user,
        logout
    } = useAuth0();

    const handleHome = () => {
        window.location.reload();
    }

    return (
        user && user.name ? (
            <React.Fragment>
                <StyleMasterAppBar
                    handleHome={handleHome}
                    username={user.name}
                    logout={() => { logout({ returnTo: window.location.origin }) }}
                />
                <div style={{ minHeight: "175px" }} />
                <div
                    style={{
                        marginTop: "20px",
                        marginBottom: "20px",
                        fontSize: "25px",
                        justifyContent: "center",
                        display: "flex",
                    }}
                >
                    You do not currently have access to this tool. Please reach out to&nbsp;
                    <a href="mailto:asquad@skyitgroup.com">asquad@skyitgroup.com</a>
                    &nbsp;for assistance.
                </div>
            </React.Fragment>
        ) : <></>
    )
}
