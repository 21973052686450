import React from 'react';
import { TextField, Box, InputAdornment, Card, Typography } from "@material-ui/core";
import DataGrid, {
    Pager, Paging, Editing, Column, FilterRow, Lookup
} from 'devextreme-react/data-grid';
import ButtonStyle from '../style/ButtonStyle';
import {
    faWandMagicSparkles
} from '@fortawesome/free-solid-svg-icons';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from 'devextreme-react/tooltip';
import { Button } from 'devextreme-react/button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import StyleBox from './styleAutoSelect';
import { Autocomplete } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CircularProgress from '@material-ui/core/CircularProgress';

const DeletedRejectionTable = ({
    rejectionList,
    hiddenRejectionList,
    updatedRows,
    deleteddataGridRef,
    dataGridRef,
    onToolbarPreparing,
    deletedRejectionsOnRowPrepared,
    allowedPageSizes,
    rdLookup,
    reasonLookup,
    assignRejectionColor,
    showStylePage,
    selectedClient,
    classes,
    selectStyleBtn,
    cancelBtn,
    selectStyleIcon,
    selectColor,
    selectSize,
    sizeChange,
    colorChange,
    selectedStyleSave,
    deleteAgainRows,
    cellPrepared,
    changeColumnWidth,
    updatedColorOptions,
    updatedSizeOptions,
    style,
    upc
}) => {

    const colorCellRender = (options) => {
        // <CircularProgress  className={classes.spinnerIcon} />

        return options.key.stm_nrf_color !== null ?
            <Typography variant='div'>{options.key.stm_nrf_color}</Typography>
            :
            updatedColorOptions[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]] ?
                <>
                    <Autocomplete
                        className={classes.selectColor}
                        id="custom-input-demo"
                        options={updatedColorOptions[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]]}
                        getOptionLabel={(option) => option.label}
                        getOptionSelected={(option, value) => option.label === value.label}
                        value={updatedRows[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]] ? updatedRows[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]].stm_nrf_color : null}
                        onChange={(_, _metric) => {
                            if (_metric !== null) {
                                colorChange(_metric, options.data, options.row.loadIndex, options)
                            }
                        }}
                        size="small"
                        disableClearable
                        renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Select a Color" />}
                        ListboxComponent={(props) => (
                            <ul {...props} className="autoCompleteField">
                                {React.Children.map(props.children, (child) => {
                                    return React.cloneElement(child, {
                                        ...child.props,
                                        className: 'autoCompleteList',
                                    });
                                })}
                            </ul>
                        )}
                    />
                </>
                :
                (updatedColorOptions[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]] === null) ?
                    <CircularProgress className={classes.spinnerIcon} />
                    :
                    options.data.stm_style !== null ?
                        <>
                            {/* <CircularProgress className={classes.spinnerIcon}/> */}
                            {selectColor(options)}
                        </>
                        :
                        ''
    }


    const cellSizeRender = (options) => {
        return options.key.stm_size !== null ?
            <Typography variant='div'>{options.key.stm_size}</Typography>
            :
            updatedSizeOptions[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]] ?

                <Autocomplete
                    className={classes.selectColor}
                    id="custom-input-demo"
                    // options={options.value}
                    options={updatedSizeOptions[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]]}
                    value={updatedRows[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]] ? updatedRows[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]].stm_size : null}
                    onChange={(_, _metric) => {
                        if (_metric !== null) {
                            sizeChange(_metric, options.data, options.row.loadIndex, options.rowIndex, options)

                        }
                    }}
                    size="small"
                    disableClearable
                    renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Select a Size" />}
                    ListboxComponent={(props) => (
                        <ul {...props} className="autoCompleteField">
                            {React.Children.map(props.children, (child) => {
                                return React.cloneElement(child, {
                                    ...child.props,
                                    className: 'autoCompleteList',
                                });
                            })}
                        </ul>
                    )}
                />
                :
                (updatedSizeOptions[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]] === null) ?
                    <CircularProgress className={classes.spinnerIcon} />
                    :
                    (options.data.stm_nrf_color !== null && !([options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause] in updatedSizeOptions)) ?
                        // <ButtonStyle selectStyleBtn={() => selectColor(options)} btnName="Select Color" className="badSelection" />
                        selectSize(options)
                        : ''
    }

    return (
        <Card style={{ padding: "10px" }}>
            <DataGrid className="sTable"
                columnAutoWidth={true}
                allowColumnResizing={true}
                ref={deleteddataGridRef}
                dataSource={hiddenRejectionList}
                showBorders={true}
                showRowLines={true}
                showColumnLines={false}
                showScrollbar={true}
                onToolbarPreparing={onToolbarPreparing}
                onRowPrepared={deletedRejectionsOnRowPrepared}
                onCellPrepared={cellPrepared}
                id='deletedRejections'
            >

                <FilterRow visible={true} />

                <Paging
                    defaultPageSize={25}
                />

                <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    showPageSizeSelector={true}
                    showNavigationButtons={true}
                />
                <Editing
                    mode="batch"
                    allowUpdating={true}
                    allowDeleting={true}
                    useIcons={true}
                />


                <Column dataField="department" caption="Retailer Dept" allowEditing={false}
                >
                    <Lookup dataSource={rdLookup} />
                </Column>
                <Column dataField="src_style" caption="Rejected Style" allowEditing={false} cellTemplate={assignRejectionColor} />

                <Column dataField="src_color" caption="Rejected Color" allowEditing={false} cellTemplate={assignRejectionColor} />
                {rejectionList && rejectionList[0].src_size !== "IGNORE" ?
                    <Column dataField="src_size" caption="Rejected Size" allowEditing={false} cellTemplate={assignRejectionColor} /> :
                    <></>
                }

                <Column dataField="rejection_cause" caption="Reason" allowEditing={false}>
                    <Lookup dataSource={reasonLookup} />
                </Column>
                {/* <Column dataField="stm_style" caption="Updated Style" allowEditing={false} width='340' */}
                <Column dataField="stm_style" caption="Updated Style" allowEditing={false}
                    //editCellComponent={() => StyleBox(styleList)}
                    allowFiltering={false} allowSorting={false}

                    cellRender={(container) => {

                        return <>
                            <Box display="flex" justifyContent="start" m={0} p={0}>

                                {
                                    showStylePage && showStylePage.has(container.rowIndex) ?
                                        <StyleBox options={container} selectedClient={selectedClient} style={style} upc={upc} cancelBtn={() => cancelBtn(container)} selectedStyleSave={(selectedStyle, upc_code) => selectedStyleSave(selectedStyle, upc_code, container.row.loadIndex, container)} />
                                        :

                                        container.key.stm_style && container.key.stm_nrf_color ?
                                            <Typography variant="div">{container.key.stm_style}</Typography>
                                            // : container.value ?
                                            : container.key.stm_style ?
                                                <>
                                                    <ButtonStyle selectStyleBtn={() => changeColumnWidth(container, 'btnClick')} btnName={container.value} className="goodSelection" />
                                                </>
                                                : [container.data.clientId, container.data.tpid, container.data.src_style, container.data.src_color, container.data.src_size, container.data.rejection_cause] in updatedRows && updatedRows[[container.data.clientId, container.data.tpid, container.data.src_style, container.data.src_color, container.data.src_size, container.data.rejection_cause]].stm_style ?
                                                    <>
                                                        <ButtonStyle selectStyleBtn={() => changeColumnWidth(container, 'btnClick')} btnName={updatedRows[[container.data.clientId, container.data.tpid, container.data.src_style, container.data.src_color, container.data.src_size, container.data.rejection_cause]].stm_style} className="goodSelection" />
                                                    </> :

                                                    <>
                                                        <ButtonStyle selectStyleBtn={() => changeColumnWidth(container, 'btnClick')} btnName="Select Style" className="badSelection" />

                                                        <IconButton p={0} onClick={() => selectStyleIcon(container, 'iconClick')} aria-label="create" className={classes.btnIconHover} disableElevation>
                                                            <FontAwesomeIcon icon={faWandMagicSparkles} />
                                                        </IconButton>
                                                    </>
                                }
                                {/* </div> */}
                            </Box>
                        </>;
                    }}
                />

                {selectedClient !== "LOR" ?
                    <Column dataField="stm_nrf_color" caption="Updated Color" allowEditing={false} allowFiltering={false} allowSorting={false}
                        cellRender={colorCellRender}
                    />
                    : <></>}
                {rejectionList && rejectionList[0].src_size !== "IGNORE" && selectedClient !== "LOR" ?
                    <Column dataField="stm_size" caption="Updated Size" allowEditing={false} allowFiltering={false} allowSorting={false}
                        cellRender={cellSizeRender}
                    //     cellRender={(options) => {
                    //         return options.key.stm_nrf_color !== null ?
                    //             <Typography variant='div'>{options.key.stm_nrf_color}</Typography>
                    //             :
                    //             // Array.isArray(options.value) ?
                    //             updatedColorOptions[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]] ?
                    //                 <Autocomplete
                    //                     className={classes.selectColor}
                    //                     id="custom-input-demo"
                    //                     // options={options.value}
                    //                     options={updatedColorOptions[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]]}
                    //                     value={updatedRows[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]] ? updatedRows[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]].stm_nrf_color : null}
                    //                     onChange={(_, _metric) => {
                    //                         if (_metric !== null) {
                    //                             colorChange(_metric, options.data, options.row.loadIndex, options)
                    //                         }
                    //                     }}
                    //                     size="small"
                    //                     disableClearable
                    //                     renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Select a Color" />}
                    //                     ListboxComponent={(props) => (
                    //                         <ul {...props} className="autoCompleteField">
                    //                             {React.Children.map(props.children, (child) => {
                    //                                 return React.cloneElement(child, {
                    //                                     ...child.props,
                    //                                     className: 'autoCompleteList',
                    //                                 });
                    //                             })}
                    //                         </ul>
                    //                     )}
                    //                 />
                    //                 :
                    //                 // (options.data.stm_style !== null || (updatedRows[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]] && updatedRows[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]].stm_style)) ?
                    //                 (options.data.stm_style !== null && !([options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause] in updatedColorOptions)) ?
                    //                     // <ButtonStyle selectStyleBtn={() => selectColor(options)} btnName="Select Color" className="badSelection" />
                    //                     selectColor(options)
                    //                     :
                    //                     // ([options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause] in updatedColorOptions && updatedColorOptions[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]] === null) ?
                    //                     //         <CircularProgress style={{ color: '#54B948' }}  />:
                    //                         ''
                    //     }}
                    // />
                    // {rejectionList && rejectionList[0].src_size !== "IGNORE" ?
                    //     <Column dataField="stm_size" caption="Updated Size" allowEditing={false} allowFiltering={false} allowSorting={false}
                    //         cellRender={(options) => {
                    //             return options.key.stm_size !== null ?
                    //                 <Typography variant='div'>{options.key.stm_size}</Typography>
                    //                 :
                    //                 // Array.isArray(options.value) ?
                    //                 updatedSizeOptions[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]] ?
                    //                     <Autocomplete
                    //                         className={classes.selectColor}
                    //                         id="custom-input-demo"
                    //                         // options={options.value}
                    //                         options={updatedSizeOptions[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]]}
                    //                         value={updatedRows[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]] ? updatedRows[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]].stm_size : null}
                    //                         onChange={(_, _metric) => {
                    //                             if (_metric !== null) {
                    //                                 sizeChange(_metric, options.data, options.row.loadIndex, options.rowIndex, options)

                    //                             }
                    //                         }}
                    //                         size="small"
                    //                         disableClearable
                    //                         renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Select a Size" />}
                    //                         ListboxComponent={(props) => (
                    //                             <ul {...props} className="autoCompleteField">
                    //                                 {React.Children.map(props.children, (child) => {
                    //                                     return React.cloneElement(child, {
                    //                                         ...child.props,
                    //                                         className: 'autoCompleteList',
                    //                                     });
                    //                                 })}
                    //                             </ul>
                    //                         )}
                    //                     />
                    //                     :
                    //                     // (options.data.stm_nrf_color !== null && !Array.isArray(options.data.stm_nrf_color)) || ([options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause] in updatedRows && updatedRows[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]].stm_nrf_color) ?
                    //                     //     <ButtonStyle selectStyleBtn={() => selectSize(options)} btnName="Select Size" className="badSelection" />
                    //                     (options.data.stm_nrf_color !== null && !([options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause] in updatedSizeOptions)) ?
                    //                     // <ButtonStyle selectStyleBtn={() => selectColor(options)} btnName="Select Color" className="badSelection" />
                    //                     selectSize(options)
                    //                         : ''
                    //         }}
                    /> :
                    <></>
                }

                <Column dataField="qs" caption="Sls U" allowEditing={false} allowFiltering={false} allowSorting={true} format="###,###,##0" />
                <Column dataField="qa" caption="OH U" allowEditing={false} allowFiltering={false} allowSorting={true} format="###,###,##0" />

                <Column
                    type="buttons"
                    cellRender={(options) => (
                        <div className="dx-data-grid-cell-value">
                            {[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause] in updatedRows && ['N', 'R'].includes(updatedRows[[options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]].process_flag) ? <Button
                                hint="Delete"
                                icon="trash"
                                onClick={() => {
                                    let key = [options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause];
                                    let curData = key in updatedRows ? JSON.parse(JSON.stringify(updatedRows[key])) : JSON.parse(JSON.stringify(options.data));
                                    curData.process_flag = 'D';
                                    delete curData.status;

                                    deleteAgainRows.add(key.toString())
                                    updatedRows[key] = curData;
                                    deleteddataGridRef.current.instance.repaintRows(options.rowIndex);
                                }}
                            /> : <Button
                                hint="Undo"
                                icon="undo"
                                onClick={() => {
                                    let key = [options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause]
                                    let curData = key in updatedRows ? JSON.parse(JSON.stringify(updatedRows[key])) : JSON.parse(JSON.stringify(options.data));
                                    curData.process_flag = 'N';
                                    curData.status = 1;

                                    deleteAgainRows.delete(key.toString())
                                    updatedRows[key] = curData;
                                    deleteddataGridRef.current.instance.repaintRows(options.rowIndex);
                                }}
                            />}
                            <Button
                                icon="info"
                                className={`info-button-${options.row.loadIndex}`}
                            />
                            <Tooltip
                                target={`.info-button-${options.row.loadIndex}`}
                                position="left"
                                showEvent="mouseenter"
                                hideEvent="mouseleave"
                            >
                                <div>STM Style Desc: {options.data.style_desc}</div>
                                <div>Week End: {options.data.week_ending_date}</div>
                                <div>Product Desc: {options.data.product_desc}</div>
                                <div>Filename: {options.data.file_name}</div>
                            </Tooltip>
                        </div>
                    )}
                />

            </DataGrid>
        </Card>
    );
};

export default DeletedRejectionTable;