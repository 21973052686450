/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { makeStyles, Container } from "@material-ui/core";
import { Button } from 'devextreme-react/button';
import '../style/customStyle.css';
import $ from 'jquery';
import Fuse from 'fuse.js'
import { useAuth0 } from '@auth0/auth0-react';
import SelectClientRetailer from './SelectClientRetailer';
import RejectionTable from './RejectionsTable';
import DeletedRejectionTable from './DeletedRejectionsTable';
import CircularProgress from '@material-ui/core/CircularProgress';
import { VerticaApiContext } from '../../contexts/verticaApiContext';
import ReactDOM from 'react-dom';

const useStyles = makeStyles((theme) => ({
    button: {
        display: "block",
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    helpcenter: {
        position: 'absolute',
        right: 5,
        top: 5,

    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100vh',
        margin: '-65px auto',
        alignItems: 'center',
    },
    btnHover: {
        fontStyle: 'italic',

        textDecoration: 'none',
        borderBottom: 'dashed 1px #428bca',
        '&:hover': {
            textDecoration: 'none'
        },
        '& .goodSelection': {
            color: 'green'
        },
        '& .badSelection': {
            color: '#D14',
        }

    },
    btnIconHover: {
        fontSize: '14px',
        padding: 0,
        marginLeft: '10px',
        color: '#337ab7',
        '&:hover': {
            backgroundColor: 'transparent',
            color: '#23527c',
            textDecoration: 'underline'
        }

    },
    selectColor: {
        fontSize: '12px',
        '& input': {
            padding: '0px 0 7px',
            fontSize: '14px'
        },

    },
    spinnerIcon: {
        color: '#54B948'
    }
}));

export default function VerticaPage({ userClient, setIsLoading }) {
    const classes = useStyles();
    const { getClient } = useContext(VerticaApiContext);
    const { getVerticaStyle, getVerticaStyleColor, getVerticaStyleColorSize, getRejection, getHiddenRejection, submit } = useContext(VerticaApiContext);

    let [retailerList, setRetailerList] = React.useState([]);
    let [clientData, setClientData] = React.useState([]);
    let [rejectionData, setRejectionData] = React.useState([]);
    let [selectedClient, setSelectedClient] = React.useState("");
    let [clientName, setClientName] = React.useState("");
    let [colorStyle, setColorStyle] = React.useState("");
    let [dataFetched, setDataFetched] = React.useState(false);
    let [rowIndex, setRowIndex] = React.useState(null);
    let [retailerName, setRetailerName] = React.useState("");
    let [showHiddenRejectedTable, setShowHiddenRejectedTable] = React.useState(false);
    let [tableLoadDone, setTableLoadDone] = React.useState(false);
    let [style, setStyle] = React.useState([]);
    let [upc, setUPC] = React.useState({});
    let updatedRows = {};
    let showStylePage = new Set();
    let dataGridRef = React.useRef();
    let deleteddataGridRef = React.useRef();
    let deleteAgainRows = new Set();
    let updatedColorOptions = {};
    let updatedSizeOptions = {};
    let noRejections = false;
    const { user } = useAuth0();

    const [load1, setLoad1] = React.useState(true);

    useEffect(async () => {
        if (userClient) {
            let data = await getClient(userClient);
            if (data.length !== 0) {
                setClientData(data);
            }
        }
        else {
            getClient()
                .then((data) => {
                    if (data.length !== 0) {
                        setClientData(data);
                    }
                    else {
                        noRejections = true
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, []);

    useEffect(() => {
        if (userClient && clientData.length > 0)
            handleChange1({ target: { value: clientData[0].clientName } });
    }, [clientData]);

    useEffect(() => {
        getVerticaStyle(selectedClient)
            .then((response) => {
                let styles = [];
                let upcs = {};
                response.forEach(element => {
                    if (selectedClient === "LOR") {
                        styles.push(element.style + " - " + element.upc_code);
                        upcs[element.style + " - " + element.upc_code] = element.upc_code;
                    }
                    else {
                        styles.push(element.style);
                        upcs[element.style] = element.upc_code;
                    }
                })
                setStyle(styles);
                setUPC(upcs);
                setTableLoadDone(true);
            });
        if (retailerList.length === 1) {
            handleChange2(retailerList[0])
        }
    }, [retailerList]);

    useEffect(() => {
        if (rejectionData.length > 0) {
            let styles = [];
            let allRejectedStyles = rejectionData[0];
            let allDeletedStyles = rejectionData[1];
            if (allRejectedStyles.length > 0) {
                allRejectedStyles.forEach(element => {
                    if (element.stm_style !== null) {
                        styles.push(element.stm_style)//add the styles to an array
                    }

                })
            }
            if (allDeletedStyles.length > 0) {
                allDeletedStyles.forEach(element => {
                    if (element.stm_style !== null) {
                        styles.push(element.stm_style)//add the styles to an array
                    }

                })
            }

            var stylesString = "";
            //create string of all styles to pass in the sql query
            if (0 < styles.length) {
                styles.forEach(function (value, index, arr) {
                    if (styles.length === 1) {
                        stylesString = `'${value}'`;
                    }
                    else {
                        stylesString += `'${value}'`;
                    }

                    if (index < arr.length - 1) {
                        stylesString += ", ";
                    }
                });
                getStyleColors(stylesString)
            }
            else {
                if (style.length !== 0) {
                    setColorStyle("")
                    setTableLoadDone(true)
                }
            }


        }

    }, [rejectionData]);

    useEffect(() => {
        if (rejectionList.length > 0 && style.length > 0) {
            setTableLoadDone(true)
        }
    }, [colorStyle]);

    const getStyleColors = (stylesString) => {

        getVerticaStyleColor(selectedClient, stylesString)
            .then((response) => {

                setColorStyle(response)
            })
            .catch((error) => {
                console.log(error);
            });
    };

    let clientList = clientData ? clientData.map(ele => {
        return ele.clientName;
    }) : []

    clientList = clientList.filter(
        (element, i) => i === clientList.indexOf(element)
    )

    const handleChange1 = (event) => {
        let val = event.target.value;
        setClientName(val);
        setStyle([]);
        setRetailerList(clientData.filter(x => x.clientName === val).map(x => x.corporate_entity))
        setSelectedClient(clientData.filter(x => x.clientName === val)[0].clientId)
        setRetailerName("");
    };

    const handleChange2 = (event) => {
        let val = event.target ? event.target.value : event;
        let tpid = clientData.filter(x => x.corporate_entity === val)[0].tpid
        setDataFetched(false);
        Promise.all([
            getRejection(selectedClient, tpid),
            getHiddenRejection(selectedClient, tpid)
        ]).then(([rej, rejHidden]) => {
            setRejectionData([rej, rejHidden]);
            setDataFetched(true);
        }).catch(error => {
            console.log(error);
            setDataFetched(true);
        });

        setRetailerName(val);
        setTableLoadDone(false)
    };

    const showTableHandle = () => {
        setShowHiddenRejectedTable(!showHiddenRejectedTable);
    };

    const allowedPageSizes = [10, 25, 50, 100];

    let rejectionList = rejectionData.length > 0 ? rejectionData[0] : [];

    let hiddenRejectionList = rejectionData.length > 0 ? rejectionData[1] : [];

    let rdLookup = rejectionList.length > 0 ? rejectionList.map(d => d.department).filter((val, index, self) => self.indexOf(val) === index).sort() : []
    let reasonLookup = rejectionList.length > 0 ? rejectionList.map(d => d.rejection_cause).filter((val, index, self) => self.indexOf(val) === index).sort() : []

    const handleSubmit = () => {
        if (Object.keys(updatedRows).length !== 0) {
            setIsLoading(true);
            submit(showHiddenRejectedTable, updatedRows, user.nickname, new Date())
                .then(() => {
                    handleChange2(retailerName)
                    setIsLoading(false);
                    alert("Submit successful!");
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                    alert(`Submit was unsuccessful. Reason: ${error.message}`);
                });
        }
    }

    const calculateSummary = (column) => {
        if (column === 'deletedQS') {
            return hiddenRejectionList.reduce((acc, item) => acc + item.qs, 0).toLocaleString()
        } else if (column === 'deletedQA') {
            return hiddenRejectionList.reduce((acc, item) => acc + item.qa, 0).toLocaleString()
        }
        else if (column === 'QS') {
            return rejectionList.reduce((acc, item) => acc + item.qs, 0).toLocaleString()
        } else
            return rejectionList.reduce((acc, item) => acc + item.qa, 0).toLocaleString();
    };

    const cellPrepared = (e) => {
        if (e.rowType === "filter") {
            e.cellElement.style.fontWeight = "bold"
            e.cellElement.style.color = '#333';
            if (e.column.dataField === "qs") {
                if (e.element.id === 'deletedRejections') {
                    e.cellElement.innerHTML = calculateSummary('deletedQS');
                } else
                    e.cellElement.innerHTML = calculateSummary('QS');
            }
            if (e.column.dataField === "qa") {
                if (e.element.id === 'deletedRejections') {
                    e.cellElement.innerHTML = calculateSummary('deletedQA');
                } else
                    e.cellElement.innerHTML = calculateSummary('QA');
            }
        }
        if (e.rowType === 'header') {
            e.cellElement.style.fontWeight = 'bold';
            e.cellElement.style.color = '#333';
        }
    }

    const rejectionsOnRowPrepared = (e) => {
        if (e.data && clientData.filter(x => x.corporate_entity === retailerName)[0].tpid === e.data.tpid) {
            let key = [e.data.clientId, e.data.tpid, e.data.src_style, e.data.src_color, e.data.src_size, e.data.rejection_cause];
            if (e.cells.length > 9) {
                if (updatedRows.hasOwnProperty(key) && updatedRows[key].stm_size) {
                    e.rowElement.style.backgroundColor = "rgb(193,255,193)"
                    if (updatedRows[key].process_flag === 'N') {
                        let curData = JSON.parse(JSON.stringify(updatedRows[key]))
                        curData.process_flag = 'R';
                        curData.status = 1;

                        updatedRows[key] = curData;
                        if (deleteddataGridRef.current) {
                            deleteddataGridRef.current.instance.repaintRows(e.rowIndex);
                        } else
                            dataGridRef.current.instance.repaintRows(e.rowIndex);
                    }
                }
            }
            else {
                if (updatedRows.hasOwnProperty(key) && updatedRows[key].stm_nrf_color) {
                    e.rowElement.style.backgroundColor = "rgb(193,255,193)"
                    if (updatedRows[key].process_flag === 'N') {
                        let curData = JSON.parse(JSON.stringify(updatedRows[key]))
                        curData.process_flag = 'R';
                        curData.status = 1;

                        updatedRows[key] = curData;
                        if (deleteddataGridRef.current) {
                            deleteddataGridRef.current.instance.repaintRows(e.rowIndex);
                        } else
                            dataGridRef.current.instance.repaintRows(e.rowIndex);
                    }
                }
            }

            if (selectedClient === "LOR") {
                if (updatedRows.hasOwnProperty(key) && updatedRows[key].stm_style) {
                    e.rowElement.style.backgroundColor = "rgb(193,255,193)"
                    if (updatedRows[key].process_flag === 'N') {
                        let curData = JSON.parse(JSON.stringify(updatedRows[key]))
                        curData.process_flag = 'R';
                        curData.status = 1;

                        updatedRows[key] = curData;
                        if (deleteddataGridRef.current) {
                            deleteddataGridRef.current.instance.repaintRows(e.rowIndex);
                        } else
                            dataGridRef.current.instance.repaintRows(e.rowIndex);
                    }
                }
            }

            if ((e.data.process_flag === 'P' && !updatedRows.hasOwnProperty(key)) || (updatedRows.hasOwnProperty(key) && updatedRows[key].process_flag === 'P')) {
                e.rowElement.style.backgroundColor = "rgb(255,255,210)"
            }

            if (updatedRows.hasOwnProperty(key) && updatedRows[key].process_flag === 'D') {
                e.rowElement.style.backgroundColor = "rgb(250,145,150)"
            }
        }
    }



    const deletedRejectionsOnRowPrepared = (e) => {
        if (e.data) {
            let key = [e.data.clientId, e.data.tpid, e.data.src_style, e.data.src_color, e.data.src_size, e.data.rejection_cause];
            e.rowElement.style.backgroundColor = "rgb(250,145,150)"
            if (e.cells.length > 9) {
                if (deleteAgainRows.has(key.toString())) {
                    e.rowElement.style.backgroundColor = "rgb(250,145,150)"
                }

                else if (updatedRows.hasOwnProperty(key) && updatedRows[key].stm_size) {
                    e.rowElement.style.backgroundColor = "rgb(193,255,193)"
                    if (updatedRows[key].process_flag !== 'R') {
                        let curData = JSON.parse(JSON.stringify(updatedRows[key]))
                        curData.process_flag = 'R';
                        curData.status = 1;

                        updatedRows[key] = curData;
                        if (deleteddataGridRef.current) {
                            deleteddataGridRef.current.instance.repaintRows(e.rowIndex);
                        } else
                            dataGridRef.current.instance.repaintRows(e.rowIndex);
                    }
                }

                else if (key in updatedRows && updatedRows[key].process_flag === 'N') {
                    e.rowElement.style.backgroundColor = "rgb(255,255,255)"
                }
            }
            else {
                if (deleteAgainRows.has(key.toString())) {
                    e.rowElement.style.backgroundColor = "rgb(250,145,150)"
                }

                else if (updatedRows.hasOwnProperty(key) && updatedRows[key].stm_nrf_color) {
                    e.rowElement.style.backgroundColor = "rgb(193,255,193)"
                    if (updatedRows[key].process_flag !== 'R') {
                        let curData = JSON.parse(JSON.stringify(updatedRows[key]))
                        curData.process_flag = 'R';
                        curData.status = 1;

                        updatedRows[key] = curData;
                        if (deleteddataGridRef.current) {
                            deleteddataGridRef.current.instance.repaintRows(e.rowIndex);
                        } else
                            dataGridRef.current.instance.repaintRows(e.rowIndex);
                    }
                }
                else if (key in updatedRows && updatedRows[key].process_flag === 'N') {
                    e.rowElement.style.backgroundColor = "rgb(255,255,255)"
                }
            }
            if (selectedClient === "LOR") {
                if (deleteAgainRows.has(key.toString())) {
                    e.rowElement.style.backgroundColor = "rgb(250,145,150)"
                }
                else if (updatedRows.hasOwnProperty(key) && updatedRows[key].stm_style) {
                    e.rowElement.style.backgroundColor = "rgb(193,255,193)"
                    if (updatedRows[key].process_flag !== 'R') {
                        let curData = JSON.parse(JSON.stringify(updatedRows[key]))
                        curData.process_flag = 'R';
                        curData.status = 1;

                        updatedRows[key] = curData;
                        if (deleteddataGridRef.current) {
                            deleteddataGridRef.current.instance.repaintRows(e.rowIndex);
                        } else
                            dataGridRef.current.instance.repaintRows(e.rowIndex);
                    }
                }
                else if (key in updatedRows && updatedRows[key].process_flag === 'N') {
                    e.rowElement.style.backgroundColor = "rgb(255,255,255)"
                }
            }

        }
    }

    const assignRejectionColor = (container, options) => {
        if (options && clientData.filter(x => x.corporate_entity === retailerName)[0].tpid === options.data.tpid) {

            let key = [options.data.clientId, options.data.tpid, options.data.src_style, options.data.src_color, options.data.src_size, options.data.rejection_cause];
            $("<div>").append(options.value).appendTo(container);
            if (selectedClient !== "LOR") {
                if (options.values.length > 9) {
                    if (options.columnIndex === 1) {
                        if (([null, ""].includes(options.values[options.columnIndex + 4]) && !(key in updatedRows)) || (key in updatedRows && updatedRows[key].stm_style === null)) {
                            $(container).attr("class", "rejected_style");
                        }
                    }
                    else if (options.columnIndex === 2) {
                        if ((([null, ""].includes(options.values[options.columnIndex + 4]) || Array.isArray(options.values[options.columnIndex + 4])) && !(key in updatedRows)) || (key in updatedRows && updatedRows[key].stm_nrf_color === null)) {
                            $(container).attr("class", "rejected_style");
                        }
                    }
                    else {
                        if ((([null, ""].includes(options.values[options.columnIndex + 4]) || Array.isArray(options.values[options.columnIndex + 4])) && !(key in updatedRows)) || (key in updatedRows && updatedRows[key].stm_size === null)) {
                            $(container).attr("class", "rejected_style");
                        }
                    }
                }
                else {
                    if (options.columnIndex === 1) {
                        if (([null, ""].includes(options.values[options.columnIndex + 3]) && !(key in updatedRows)) || (key in updatedRows && updatedRows[key].stm_style === null)) {
                            $(container).attr("class", "rejected_style");
                        }
                    }
                    else if (options.columnIndex === 2) {
                        if ((([null, ""].includes(options.values[options.columnIndex + 3]) || Array.isArray(options.values[options.columnIndex + 3])) && !(key in updatedRows)) || (key in updatedRows && updatedRows[key].stm_nrf_color === null)) {
                            $(container).attr("class", "rejected_style");
                        }
                    }
                }
            }
            else {
                if (options.values.length === 9) {
                    if (options.columnIndex < 4 && (!(key in updatedRows) || key in updatedRows && updatedRows[key].stm_style === null)) {
                        $(container).attr("class", "rejected_style");
                    }
                }
                else {
                    if (options.columnIndex < 3 && (!(key in updatedRows) || key in updatedRows && updatedRows[key].stm_style === null)) {
                        $(container).attr("class", "rejected_style");
                    }
                }
            }
        }
    }

    function onToolbarPreparing(e) {      // hide all undo and save buttons above the table
        e.toolbarOptions.visible = false;
    }

    const cancelBtn = () => {
        showStylePage.clear()
        if (dataGridRef.current) {
            dataGridRef.current.instance.option("dataSource", rejectionList);
            dataGridRef.current.instance.columnOption('stm_style', 'width', 'auto');
            dataGridRef.current.instance.repaintRows(0);

        }
        if (deleteddataGridRef.current) {
            deleteddataGridRef.current.instance.option("dataSource", hiddenRejectionList);
            deleteddataGridRef.current.instance.repaintRows(0);
            deleteddataGridRef.current.instance.columnOption('stm_style', 'width', 'auto');
        }

    }

    const selectedStyleSave = (style, upc_code, rowIndex, container) => {
        if (container) {
            let data = container.data;
            let key = [data.clientId, data.tpid, data.src_style, data.src_color, data.src_size, data.rejection_cause];
            let curRow = key in updatedRows ? JSON.parse(JSON.stringify(updatedRows[key])) : JSON.parse(JSON.stringify(data));
            curRow.stm_style = style;
            curRow.stm_upc_code = upc_code;
            updatedRows[key] = curRow;

            showStylePage.clear()
            container.component.cellValue(rowIndex, "Updated Style", style);
            if (selectedClient !== "LOR") {
                getColorList(selectedClient, style, container)
            }

            if (!updatedRows[key].locked) {
                for (let i = 0; i < container.component.getVisibleRows().length; i++) {
                    let otherRow = container.component.getVisibleRows()[i].data;
                    let key = [otherRow.clientId, otherRow.tpid, otherRow.src_style, otherRow.src_color, otherRow.src_size, otherRow.rejection_cause];
                    if (rowIndex !== i && otherRow.src_style === container.data.src_style && !(key in updatedColorOptions) && (!(key in updatedRows) || (key in updatedRows && !updatedRows[key].locked))) {
                        let row = JSON.parse(JSON.stringify(otherRow));
                        row.stm_style = style;
                        row.stm_upc_code = upc_code;
                        updatedRows[key] = row;
                        if (selectedClient !== "LOR") {
                            getColorList(selectedClient, style, container.component.getVisibleRows()[i])
                        }
                    }
                }
            }
        }
    }

    const getColorList = (client, style, container) => {
        let data = container.data;
        let key = [data.clientId, data.tpid, data.src_style, data.src_color, data.src_size, data.rejection_cause];
        let curRow = key in updatedRows ? JSON.parse(JSON.stringify(updatedRows[key])) : JSON.parse(JSON.stringify(data));

        curRow.stm_style = style;
        updatedRows[key] = curRow;
        updatedColorOptions[key] = null;
        let stylesString = `'${style}'`;

        getVerticaStyleColor(client, stylesString)
            .then((response) => {
                let colors = []
                if (response.length > 0) {
                    response.forEach(element => {
                        if (element.nrf_color_code && element.nrf_color_code.length > 0) {
                            colors.push({
                                label: element.color_desc + ' - ' + element.nrf_color_code,
                                value: element.nrf_color_code
                            });

                            upc[element.color_desc + ' - ' + element.nrf_color_code] = element.upc_code
                        } else {
                            colors.push({
                                label: element.color_desc,
                                value: element.color_desc
                            });
                            upc[element.color_desc] = element.upc_code
                        }
                    });
                }

                updatedColorOptions[key] = colors;

                const findColor = colors.find(color => color.label === container.data.src_color);
                if (findColor) {
                    let curRow = key in updatedRows ? JSON.parse(JSON.stringify(updatedRows[key])) : JSON.parse(JSON.stringify(container.data));
                    curRow.stm_nrf_color = findColor
                    curRow.stm_upc_code = upc[findColor.label]
                    updatedRows[key] = curRow;
                }

                if (dataGridRef.current) {
                    dataGridRef.current.instance.option("dataSource", rejectionList);
                    dataGridRef.current.instance.repaintRows(container.rowIndex);
                    dataGridRef.current.instance.columnOption('stm_style', 'width', 'auto');
                }
                if (deleteddataGridRef.current) {
                    deleteddataGridRef.current.instance.repaintRows(container.rowIndex);
                    deleteddataGridRef.current.instance.columnOption('stm_style', 'width', 'auto');
                }
            })
    }


    const selectStyleBtn = (e, val) => {
        showStylePage.clear();
        showStylePage.add(e.rowIndex);
        dataGridRef.current.instance.repaintRows();

    };
    const selectStyleBtnDel = (e, val) => {
        showStylePage.clear();
        showStylePage.add(e.rowIndex);
        deleteddataGridRef.current.instance.repaintRows(e.rowIndex);

    };

    const selectSize = (e, colorVal) => {

        let data = e.data
        let key = [data.clientId, data.tpid, data.src_style, data.src_color, data.src_size, data.rejection_cause];

        let style = key in updatedRows && updatedRows[key].stm_style ? updatedRows[key].stm_style : data.stm_style;
        let color = key in updatedRows && updatedRows[key].stm_nrf_color ? updatedRows[key].stm_nrf_color.label.split("-")[0] : data.stm_nrf_color.split("-")[0];
        if (colorVal) {
            color = colorVal.substr(0, colorVal.lastIndexOf("-")).trim();
        }

        updatedSizeOptions[key] = null;

        getVerticaStyleColorSize(selectedClient, style, color)
            .then((response) => {
                let sizes = []
                if (response.length > 0) {
                    response.forEach(element => {
                        sizes.push(element.size);
                        upc[element.size] = element.upc_code
                    });
                    sizes.sort()
                }

                updatedSizeOptions[key] = sizes;
                const findSize = sizes.includes(e.data.src_size);
                if (findSize) {

                    let curRow = key in updatedRows ? JSON.parse(JSON.stringify(updatedRows[key])) : JSON.parse(JSON.stringify(e.data));
                    curRow.stm_size = e.data.src_size
                    curRow.stm_upc_code = upc[e.data.src_size]
                    curRow.process_flag = 'N'

                    updatedRows[key] = curRow;
                }
                if (deleteddataGridRef.current) {
                    deleteddataGridRef.current.instance.repaintRows(e.rowIndex);
                } else
                    dataGridRef.current.instance.repaintRows(e.rowIndex);
            })
            .catch((error) => {
                console.log(error);
            });
    }



    const selectColor = (e) => {
        let data = e.data
        let key = [data.clientId, data.tpid, data.src_style, data.src_color, data.src_size, data.rejection_cause];
        let style = key in updatedRows && updatedRows[key].stm_style ? updatedRows[key].stm_style : data.stm_style
        updatedColorOptions[key] = null;

        let colorStyleCombo = []
        if (colorStyle) {
            let test1 = colorStyle.filter(x => {
                return x.style === style
            })

            test1.forEach(element => {
                if (element.nrf_color_code && element.nrf_color_code.length > 0) {
                    colorStyleCombo.push({
                        label: element.color_desc + ' - ' + element.nrf_color_code,
                        value: element.nrf_color_code
                    });
                    upc[element.color_desc + ' - ' + element.nrf_color_code] = element.upc_code
                }
                else if (element.color_desc) {
                    colorStyleCombo.push({
                        label: element.color_desc,
                        value: element.color_desc
                    });
                    upc[element.color_desc] = element.upc_code
                }
            });
        }
        updatedColorOptions[key] = colorStyleCombo;
        setLoad1(true);

        const findColor = colorStyleCombo.find(color => color.label === e.data.src_color);
        if (findColor) {
            let curRow = key in updatedRows ? JSON.parse(JSON.stringify(updatedRows[key])) : JSON.parse(JSON.stringify(e.data));
            curRow.stm_nrf_color = findColor
            curRow.stm_upc_code = upc[findColor.label]

            updatedRows[key] = curRow;
        }

        if (deleteddataGridRef.current) {
            deleteddataGridRef.current.instance.repaintRows(e.rowIndex);
        } else
            dataGridRef.current.instance.repaintRows(e.rowIndex);



    }

    const colorChange = (value, data, loadIndex, e) => {
        let key = [data.clientId, data.tpid, data.src_style, data.src_color, data.src_size, data.rejection_cause];
        let curRow = key in updatedRows ? JSON.parse(JSON.stringify(updatedRows[key])) : JSON.parse(JSON.stringify(data));
        curRow.stm_nrf_color = value
        if (e.values.length === 9) {
            curRow.stm_upc_code = upc[value.label]
            curRow.process_flag = 'N'
        }
        e.component.cellValue(e.rowIndex, "Updated Color", value.label);
        updatedRows[key] = curRow;
        if (e.values.length > 9) {
            selectSize(e, value.label)
        }
        else {
            if (deleteddataGridRef.current) {
                deleteddataGridRef.current.instance.repaintRows(e.rowIndex);
            } else
                dataGridRef.current.instance.repaintRows(loadIndex);
        }

        for (let i = 0; i < e.component.getVisibleRows().length; i++) {
            let otherRow = e.component.getVisibleRows()[i].data;
            let key = [otherRow.clientId, otherRow.tpid, otherRow.src_style, otherRow.src_color, otherRow.src_size, otherRow.rejection_cause];
            if (i !== loadIndex && otherRow.src_style === e.data.src_style && otherRow.src_color === e.data.src_color && !(key in updatedSizeOptions)) {
                let row = key in updatedRows ? updatedRows[key] : JSON.parse(JSON.stringify(otherRow));
                row.stm_nrf_color = value;
                if (e.values.length === 9) {
                    curRow.stm_upc_code = upc[value.label]
                }
                updatedRows[key] = row;
                if (e.values.length > 9) {
                    selectSize(e.component.getVisibleRows()[i], value.label)
                }
                else {
                    if (deleteddataGridRef.current) {
                        deleteddataGridRef.current.instance.repaintRows(i);
                    } else
                        dataGridRef.current.instance.repaintRows(i);
                }
            }
        }

    };

    const sizeChange = (value, data, loadIndex, rowIndex, e) => {
        let key = [data.clientId, data.tpid, data.src_style, data.src_color, data.src_size, data.rejection_cause];

        let curRow = key in updatedRows ? JSON.parse(JSON.stringify(updatedRows[key])) : JSON.parse(JSON.stringify(data));
        curRow.stm_size = value
        curRow.stm_upc_code = upc[value]
        curRow.process_flag = 'N'

        updatedRows[key] = curRow;
        if (deleteddataGridRef.current) {
            deleteddataGridRef.current.instance.repaintRows(rowIndex);
        } else
            dataGridRef.current.instance.repaintRows(rowIndex);

        for (let i = 0; i < e.component.getVisibleRows().length; i++) {
            let otherRow = e.component.getVisibleRows()[i].data;
            let key = [otherRow.clientId, otherRow.tpid, otherRow.src_style, otherRow.src_color, otherRow.src_size, otherRow.rejection_cause];
            if (i !== loadIndex && otherRow.src_style === e.data.src_style && otherRow.src_color === e.data.src_color && otherRow.src_size === e.data.src_size && (!(key in updatedRows) || (key in updatedRows && !updatedRows[key].stm_size))) {
                let row = key in updatedRows ? updatedRows[key] : JSON.parse(JSON.stringify(otherRow));
                row.stm_size = value;
                row.stm_upc_code = upc[value];

                row.process_flag = 'N'
                updatedRows[key] = row;
                dataGridRef.current.instance.repaintRows(i);
            }
        }

    };

    const selectStyleIcon = (event) => {

        event.cellElement.innerHTML = "Searching..."

        if (!Array.isArray(event)) {

            const searcher = new Fuse(style, {
                caseSensitive: false,
                includeScore: false,
                shouldSort: true,
                threshold: 0.25,
                location: 0,
                distance: 400,
                maxPatternLength: 15,
            });
            const result = searcher.search(event.key.src_style);
            let data = event.data
            let key = [data.clientId, data.tpid, data.src_style, data.src_color, data.src_size, data.rejection_cause];

            if (result.length > 0) {

                let foundStyle = result[0].item;
                let curRow = key in updatedRows ? JSON.parse(JSON.stringify(updatedRows[key])) : JSON.parse(JSON.stringify(data));
                curRow.stm_style = foundStyle
                curRow.stm_upc_code = upc[foundStyle];

                updatedRows[key] = curRow;

                event.component.cellValue(event.rowIndex, "Updated Style", foundStyle);
                if (selectedClient !== "LOR") {
                    getColorList(selectedClient, foundStyle, event)
                }

                if (!updatedRows[key].locked) {
                    for (let i = 0; i < event.component.getVisibleRows().length; i++) {
                        let otherRow = event.component.getVisibleRows()[i].data;
                        let key = [otherRow.clientId, otherRow.tpid, otherRow.src_style, otherRow.src_color, otherRow.src_size, otherRow.rejection_cause];
                        if (event.rowIndex !== i && otherRow.src_style === event.data.src_style && !(key in updatedColorOptions) && (!(key in updatedRows) || (key in updatedRows && !updatedRows[key].locked))) {
                            let row = JSON.parse(JSON.stringify(otherRow));
                            row.stm_style = foundStyle;
                            row.stm_upc_code = upc[foundStyle];
                            updatedRows[key] = row;
                            if (selectedClient !== "LOR") {
                                getColorList(selectedClient, foundStyle, event.component.getVisibleRows()[i])
                            }

                        }
                    }
                }
            } else {
                if (deleteddataGridRef.current) {
                    deleteddataGridRef.current.instance.repaintRows(event.rowIndex);
                }
                if (dataGridRef.current) {
                    dataGridRef.current.instance.repaintRows(event.rowIndex);
                }
                alert("No style found!")
            }
        }
    };

    const tableReady = rejectionList.length > 0 && clientData.length > 0 && retailerName.length > 0 ? rejectionList[0].tpid === clientData.filter(x => x.corporate_entity === retailerName)[0].tpid : false;

    const changeColumnWidth = (columnDataField, newWidth) => {
        if (deleteddataGridRef.current) {
            deleteddataGridRef.current.instance.columnOption('stm_style', 'width', '350');
            selectStyleBtnDel(columnDataField, 'btnClick')
        }
        if (dataGridRef.current) {
            dataGridRef.current.instance.option("dataSource", rejectionList);

            dataGridRef.current.instance.columnOption('stm_style', 'width', '350');
            selectStyleBtn(columnDataField, 'btnClick')
        }
    };

    let currentPage = 0;

    const onContentReady = (e) => {
        if (dataGridRef.current && dataGridRef.current.instance.pageIndex() !== currentPage) {
            showStylePage.clear()
            dataGridRef.current.instance.option("dataSource", rejectionList);
            dataGridRef.current.instance.columnOption('stm_style', 'width', '');
            dataGridRef.current.instance.repaintRows(0);
            currentPage = e.component.pageIndex()
        }

        else if (deleteddataGridRef.current && deleteddataGridRef.current.instance.pageIndex() !== currentPage) {
            showStylePage.clear()
            deleteddataGridRef.current.instance.option("dataSource", hiddenRejectionList);
            deleteddataGridRef.current.instance.columnOption('stm_style', 'width', '');
            deleteddataGridRef.current.instance.repaintRows(0);
            currentPage = e.component.pageIndex()
        }

    }

    return (
        <Container maxWidth="xl" style={{ paddingBottom: '12px' }}>
            {noRejections ?
                <div className={classes.root} >
                    <h1>No new available rejections to be resolved</h1>
                </div> :
                <SelectClientRetailer
                    rejectionList={rejectionList}
                    classes={classes}
                    clientList={clientList}
                    retailerList={retailerList}
                    clientName={clientName}
                    retailerName={retailerName}
                    handleChange1={(e) => handleChange1(e)}
                    handleChange2={(e) => handleChange2(e)}
                />
            }

            <Button style={{ position: 'absolute', top: 80, right: 30 }}
                className="helpcenter"
                variant="contained"
                color="inherit"
                onClick={() => window.open("https://skypad.zendesk.com/hc/en-us/categories/360001948172-SKYALIGN", "_blank")}
            >
                Help Center
            </Button>

            {showHiddenRejectedTable ?
                <Button style={{ position: 'absolute', top: 80, right: 130 }}
                    className="showrejections"
                    variant="contained"
                    color="primary"
                    onClick={showTableHandle}
                >
                    Show Current Rejections
                </Button> : rejectionList.length > 0 ?
                    <Button style={{ position: 'absolute', top: 80, right: 130 }}
                        className="showrejections"
                        variant="contained"
                        color="primary"
                        onClick={showTableHandle}
                    >
                        Show Deleted Rejections
                    </Button> :
                    <></>}



            { retailerName.length > 0 && !showHiddenRejectedTable && dataFetched ?
                <RejectionTable
                    rejectionList={rejectionList}
                    updatedRows={updatedRows}
                    updatedColorOptions={updatedColorOptions}
                    updatedSizeOptions={updatedSizeOptions}
                    dataGridRef={dataGridRef}
                    onToolbarPreparing={onToolbarPreparing}
                    rejectionsOnRowPrepared={rejectionsOnRowPrepared}
                    allowedPageSizes={allowedPageSizes}
                    rdLookup={rdLookup}
                    reasonLookup={reasonLookup}
                    assignRejectionColor={assignRejectionColor}
                    showStylePage={showStylePage}
                    selectedClient={selectedClient}
                    classes={classes}
                    selectStyleBtn={(e, val) => selectStyleBtn(e, val)}
                    cancelBtn={() => cancelBtn()}
                    selectStyleIcon={(e) => selectStyleIcon(e)}
                    selectColor={selectColor}
                    selectSize={selectSize}
                    sizeChange={sizeChange}
                    colorChange={colorChange}
                    selectedStyleSave={selectedStyleSave}
                    cellPrepared={cellPrepared}
                    changeColumnWidth={changeColumnWidth}
                    load1={load1}
                    tableLoadDone={tableLoadDone}
                    onContentReady={onContentReady}
                    style={style}
                    upc={upc}
                />
                : <></>
            }

            {
                showHiddenRejectedTable && retailerName.length > 0 && dataFetched ?
                    <DeletedRejectionTable
                        rejectionList={rejectionList}
                        hiddenRejectionList={hiddenRejectionList}
                        updatedRows={updatedRows}
                        deleteddataGridRef={deleteddataGridRef}
                        onToolbarPreparing={onToolbarPreparing}
                        deletedRejectionsOnRowPrepared={deletedRejectionsOnRowPrepared}
                        allowedPageSizes={allowedPageSizes}
                        rdLookup={rdLookup}
                        reasonLookup={reasonLookup}
                        assignRejectionColor={assignRejectionColor}
                        showStylePage={showStylePage}
                        selectedClient={selectedClient}
                        classes={classes}
                        selectStyleBtn={(e, val) => selectStyleBtn(e, val)}
                        cancelBtn={() => cancelBtn()}
                        selectStyleIcon={(e) => selectStyleIcon(e)}
                        selectColor={selectColor}
                        selectSize={selectSize}
                        sizeChange={sizeChange}
                        colorChange={colorChange}
                        selectedStyleSave={selectedStyleSave}
                        deleteAgainRows={deleteAgainRows}
                        cellPrepared={cellPrepared}
                        changeColumnWidth={changeColumnWidth}
                        updatedColorOptions={updatedColorOptions}
                        updatedSizeOptions={updatedSizeOptions}
                        style={style}
                        upc={upc}
                    />
                    : <></>
            }
            {
                rejectionList.length > 0 && retailerName.length > 0 && dataFetched ?
                    <Button style={{ marginLeft: 10, marginTop: 10 }}
                        className="showrejections"
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Submit Updates
                    </Button>
                    : <></>
            }

            {retailerName.length > 0 && !dataFetched ?
                <div style={{ marginTop: '10%', textAlign: 'center' }}>
                    <CircularProgress style={{ color: '#54B948' }} className='mb-3' />
                </div> : <></>
            }

        </Container >
    );
}
