import React from "react";
import "./Home.css";
import StyleMasterAppBar from "../components/StyleMasterAppBar";
import VerticaPage from "./verticaPage/verticaSelect";
import Button from "@material-ui/core/Button";
import DB2Page from "./db2Page/db2Select"
import { useAuth0 } from '@auth0/auth0-react';
import { BASE_URL } from "../apis/configs/AxiosConfig";
import isWindows from "cross-env/src/is-windows";
import LoadingOverlay from 'react-loading-overlay';

function Home() {

  const {
    user,
    isAuthenticated,
    logout
  } = useAuth0();

  const [pageType, setPageType] = React.useState("landingPage")
  const [isLoading, setIsLoading] = React.useState(false);


  const handleLandingButtons = (value) => {
    setPageType(value)
  }

  const handleHome = () => {
    // setPageType("landingPage");
    // window.location.assign(window.location.origin);
    window.location.reload();
  }

  const db2List = ['ALO', 'EIF', 'MJC', 'MKI', 'MKO', 'RNB', 'TBU'];

  let userClient = null;

  if (user) {
    if (user['http://skypad_user/groups'].includes('Sky Internal'))
      userClient = 'Sky Internal';
    else userClient = user['http://skypad_user/groups'].filter(x => x.includes('Rejections All'))[0].split(' ')[0];
  }
  let db2 = false;
  let vertica = false;

  if (userClient !== null && userClient !== 'Sky Internal') {
    if (db2List.includes(userClient))
      db2 = true;
    else vertica = true;
  }

  return (
    <div>
      {isAuthenticated && (
        <React.Fragment>
          <LoadingOverlay
            active={isLoading}
            spinner
            text='Submitting...'
          >
            <StyleMasterAppBar
              handleHome={handleHome}
              username={user.name}
              logout={() => { logout({ returnTo: window.location.origin }) }}
            />

            {userClient && userClient === 'Sky Internal' &&
              pageType === "landingPage" && (
                <div>
                  <Button
                    className="landing-button"
                    variant="contained"
                    color="primary"
                    onClick={() => handleLandingButtons("db2")}
                  >
                    DB2
                  </Button>

                  <Button
                    className="landing-button"
                    variant="contained"
                    color="primary"
                    onClick={() => handleLandingButtons("vertica")}
                  >
                    Vertica
                  </Button>

                </div>
              )}

            {pageType === "db2" && (<DB2Page setIsLoading={setIsLoading} />
            )}
            {pageType === "vertica" && (
              <VerticaPage setIsLoading={setIsLoading} />)}


            {vertica ? (
              <VerticaPage userClient={userClient} setIsLoading={setIsLoading} />
            ) : db2 ? <DB2Page userClient={userClient} setIsLoading={setIsLoading} /> : <></>}
          </LoadingOverlay>
        </React.Fragment>
      )}

    </div>
  );

}

export default Home;
