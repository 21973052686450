import axios from 'axios';

let url = window.location.origin;


// if (process.env.REACT_APP_BUILD_ENV === 'local') {
//   url = "http://localhost:3051/"

// }

if (window.location.hostname === "localhost") {
  url = "http://localhost:3051/";
  // url = "https://skyaligntest.skypad.biz/";

}

export const BASE_URL = url;

const axiosInt = axios.create({
  baseURL: BASE_URL // + '3051/'
});

axiosInt.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'There is an error!'
    )
);

export default axiosInt;