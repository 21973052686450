import React from 'react';
import { TextField, Box, InputAdornment, Card, Typography } from "@material-ui/core";
import DataGrid, {
    Pager, Paging, Editing, Column, FilterRow, Lookup
} from 'devextreme-react/data-grid';
import { CheckBox } from 'devextreme-react/check-box';
import ButtonStyle from '../style/ButtonStyle';
import '../style/customStyle.css';
import {
    faWandMagicSparkles
} from '@fortawesome/free-solid-svg-icons';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from 'devextreme-react/tooltip';
import { Button } from 'devextreme-react/button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import StyleBox from './styleAutoSelect';
import { Autocomplete } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CircularProgress from '@material-ui/core/CircularProgress';


const RejectionTable = ({
    rejectionList,
    updatedRows,
    updatedColorOptions,
    updatedSizeOptions,
    dataGridRef,
    onToolbarPreparing,
    rejectionsOnRowPrepared,
    allowedPageSizes,
    rdLookup,
    reasonLookup,
    assignRejectionColor,
    showStylePage,
    selectedClient,
    classes,
    cancelBtn,
    selectStyleIcon,
    selectColor,
    selectSize,
    sizeChange,
    colorChange,
    selectedStyleSave,
    cellPrepared,
    changeColumnWidth,
    tableLoadDone,
    onContentReady,
    dbInfo,
    styleList
}) => {
    const colorCellRender = (options) => {

        return options.key.STM_NRF_COLOR !== null ?
            <Typography variant='div'>{options.key.STM_NRF_COLOR}</Typography>
            :
            updatedColorOptions[[options.data.CLIENT, options.data.TRADE_PARTNER_ID, options.data.SRC_STYLE, options.data.SRC_COLOR, options.data.SRC_SIZE, options.data.REJECTION_CAUSE]] ?
                <>
                    <Autocomplete
                        className={classes.selectColor}
                        id="custom-input-demo"
                        options={updatedColorOptions[[options.data.CLIENT, options.data.TRADE_PARTNER_ID, options.data.SRC_STYLE, options.data.SRC_COLOR, options.data.SRC_SIZE, options.data.REJECTION_CAUSE]]}
                        getOptionLabel={(option) => option.label}
                        getOptionSelected={(option, value) => option.label === value.label}
                        value={updatedRows[[options.data.CLIENT, options.data.TRADE_PARTNER_ID, options.data.SRC_STYLE, options.data.SRC_COLOR, options.data.SRC_SIZE, options.data.REJECTION_CAUSE]] ? updatedRows[[options.data.CLIENT, options.data.TRADE_PARTNER_ID, options.data.SRC_STYLE, options.data.SRC_COLOR, options.data.SRC_SIZE, options.data.REJECTION_CAUSE]].STM_NRF_COLOR : null}
                        onChange={(_, _metric) => {
                            if (_metric !== null) {
                                colorChange(_metric, options.data, options.row.loadIndex, options)
                            }
                        }}
                        size="small"
                        disableClearable
                        renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Select a Color" />}
                        ListboxComponent={(props) => (
                            <ul {...props} className="autoCompleteField">
                                {React.Children.map(props.children, (child) => {
                                    return React.cloneElement(child, {
                                        ...child.props,
                                        className: 'autoCompleteList',
                                    });
                                })}
                            </ul>
                        )}
                    />
                </>
                :
                (updatedColorOptions[[options.data.CLIENT, options.data.TRADE_PARTNER_ID, options.data.SRC_STYLE, options.data.SRC_COLOR, options.data.SRC_SIZE, options.data.REJECTION_CAUSE]] === null) ?
                    <CircularProgress className={classes.spinnerIcon} />
                    :
                    options.data.STM_STYLE !== null ?
                        selectColor(options)
                        :
                        ''
    }

    const cellSizeRender = (options) => {
        return options.key.STM_SIZE !== null ?
            <Typography variant='div'>{options.key.STM_SIZE}</Typography>
            :
            updatedSizeOptions[[options.data.CLIENT, options.data.TRADE_PARTNER_ID, options.data.SRC_STYLE, options.data.SRC_COLOR, options.data.SRC_SIZE, options.data.REJECTION_CAUSE]] ?

                <Autocomplete
                    className={classes.selectColor}
                    id="custom-input-demo"
                    // options={options.value}
                    options={updatedSizeOptions[[options.data.CLIENT, options.data.TRADE_PARTNER_ID, options.data.SRC_STYLE, options.data.SRC_COLOR, options.data.SRC_SIZE, options.data.REJECTION_CAUSE]]}
                    value={updatedRows[[options.data.CLIENT, options.data.TRADE_PARTNER_ID, options.data.SRC_STYLE, options.data.SRC_COLOR, options.data.SRC_SIZE, options.data.REJECTION_CAUSE]] ? updatedRows[[options.data.CLIENT, options.data.TRADE_PARTNER_ID, options.data.SRC_STYLE, options.data.SRC_COLOR, options.data.SRC_SIZE, options.data.REJECTION_CAUSE]].STM_SIZE : null}
                    onChange={(_, _metric) => {
                        if (_metric !== null) {
                            sizeChange(_metric, options.data, options.row.loadIndex, options.rowIndex, options)

                        }
                    }}
                    size="small"
                    disableClearable
                    renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Select a Size" />}
                    ListboxComponent={(props) => (
                        <ul {...props} className="autoCompleteField">
                            {React.Children.map(props.children, (child) => {
                                return React.cloneElement(child, {
                                    ...child.props,
                                    className: 'autoCompleteList',
                                });
                            })}
                        </ul>
                    )}
                />
                :
                (updatedSizeOptions[[options.data.CLIENT, options.data.TRADE_PARTNER_ID, options.data.SRC_STYLE, options.data.SRC_COLOR, options.data.SRC_SIZE, options.data.REJECTION_CAUSE]] === null) ?
                    <CircularProgress className={classes.spinnerIcon} />
                    :
                    (options.data.STM_NRF_COLOR !== null && !([options.data.CLIENT, options.data.TRADE_PARTNER_ID, options.data.SRC_STYLE, options.data.SRC_COLOR, options.data.SRC_SIZE, options.data.REJECTION_CAUSE] in updatedSizeOptions)) ?
                        // <ButtonStyle selectStyleBtn={() => selectColor(options)} btnName="Select Color" className="badSelection" />
                        selectSize(options)
                        : ''
    }
    return (
        <Card style={{ padding: "6px" }}>
            {tableLoadDone ?
                <DataGrid className="sTable"
                    columnAutoWidth={true}
                    allowColumnResizing={true}
                    id="currentRejections"
                    ref={dataGridRef}
                    dataSource={rejectionList}
                    showBorders={true}
                    showRowLines={true}
                    showColumnLines={false}
                    showScrollbar={true}
                    onToolbarPreparing={onToolbarPreparing}
                    onRowPrepared={rejectionsOnRowPrepared}
                    onCellPrepared={cellPrepared}
                    onContentReady={onContentReady}
                    onInitialized={(e) => {
                        console.log('here')

                    }}
                >
                    <FilterRow visible={true} />

                    <Paging
                        defaultPageSize={25}
                    />

                    <Pager
                        visible={true}
                        allowedPageSizes={allowedPageSizes}
                        showPageSizeSelector={true}
                        showNavigationButtons={true}
                    />

                    <Editing
                        mode="batch"
                        allowUpdating={true}
                        allowDeleting={true}
                        useIcons={true}
                    />

                    <Column dataField="DEPARTMENT" caption="Retailer Dept" allowEditing={false} >
                        <Lookup dataSource={rdLookup} />
                    </Column>
                    <Column dataField="SRC_STYLE" caption="Rejected Style" allowEditing={false} cellTemplate={assignRejectionColor} />

                    <Column dataField="SRC_COLOR" caption="Rejected Color" allowEditing={false} cellTemplate={assignRejectionColor} />
                    {rejectionList && rejectionList[0].SRC_SIZE !== "IGNORE" ?
                        <Column dataField="SRC_SIZE" caption="Rejected Size" allowEditing={false} cellTemplate={assignRejectionColor} /> :
                        <></>
                    }

                    <Column dataField="REJECTION_CAUSE" caption="Reason" allowEditing={false}>
                        <Lookup dataSource={reasonLookup} />
                    </Column>
                    <Column dataField="STM_STYLE" caption="Updated Style" allowEditing={false}
                        //editCellComponent={() => StyleBox(styleList)}
                        allowFiltering={false} allowSorting={false}

                        cellRender={(container) => {
                            return <Box display="flex" justifyContent="start" m={0} p={0} style={{ alignItems: 'center' }}>

                                {
                                    showStylePage && showStylePage.has(container.rowIndex) ?
                                        <StyleBox options={container} dbInfo={dbInfo} selectedClient={selectedClient} styleList={styleList} cancelBtn={() => cancelBtn(container)} selectedStyleSave={(selectedStyle) => selectedStyleSave(selectedStyle, container.row.rowIndex, container)} />
                                        :
                                        container.key.STM_STYLE && container.key.STM_NRF_COLOR ?
                                            <Typography variant="div">{container.key.STM_STYLE}</Typography>
                                            : container.value ?
                                                <>
                                                    <ButtonStyle selectStyleBtn={() => changeColumnWidth(container)} btnName={container.value} className="goodSelection" />
                                                </>
                                                : [container.data.CLIENT, container.data.TRADE_PARTNER_ID, container.data.SRC_STYLE, container.data.SRC_COLOR, container.data.SRC_SIZE, container.data.REJECTION_CAUSE] in updatedRows && updatedRows[[container.data.CLIENT, container.data.TRADE_PARTNER_ID, container.data.SRC_STYLE, container.data.SRC_COLOR, container.data.SRC_SIZE, container.data.REJECTION_CAUSE]].STM_STYLE ?
                                                    <>
                                                        <ButtonStyle selectStyleBtn={() => changeColumnWidth(container)} btnName={updatedRows[[container.data.CLIENT, container.data.TRADE_PARTNER_ID, container.data.SRC_STYLE, container.data.SRC_COLOR, container.data.SRC_SIZE, container.data.REJECTION_CAUSE]].STM_STYLE} className="goodSelection" />
                                                    </> :
                                                    <>
                                                        <ButtonStyle selectStyleBtn={() => changeColumnWidth(container)} btnName="Select Style" className="badSelection" />

                                                        <IconButton p={0} onClick={() => selectStyleIcon(container)} aria-label="create" className={classes.btnIconHover} disableElevation>
                                                            <FontAwesomeIcon icon={faWandMagicSparkles} />
                                                        </IconButton>

                                                        <CheckBox
                                                            iconSize={9}
                                                            hint='Lock Style'
                                                            value={[container.data.CLIENT, container.data.TRADE_PARTNER_ID, container.data.SRC_STYLE, container.data.SRC_COLOR, container.data.SRC_SIZE, container.data.REJECTION_CAUSE] in updatedRows && updatedRows[[container.data.CLIENT, container.data.TRADE_PARTNER_ID, container.data.SRC_STYLE, container.data.SRC_COLOR, container.data.SRC_SIZE, container.data.REJECTION_CAUSE]].locked ? true : false}
                                                            style={{ marginLeft: 8, border: '0.1px solid black' }}
                                                            onValueChange={(e) => {
                                                                let key = [container.data.CLIENT, container.data.TRADE_PARTNER_ID, container.data.SRC_STYLE, container.data.SRC_COLOR, container.data.SRC_SIZE, container.data.REJECTION_CAUSE];

                                                                if (key in updatedRows) {
                                                                    updatedRows[key].locked = !updatedRows[key].locked;
                                                                }
                                                                else {
                                                                    let curData = JSON.parse(JSON.stringify(container.data));
                                                                    curData.locked = true;
                                                                    updatedRows[key] = curData;
                                                                }
                                                                dataGridRef.current.instance.repaintRows(container.rowIndex);
                                                            }}
                                                        />
                                                    </>
                                }
                            </Box>;
                        }}
                    />

                    <Column dataField="STM_NRF_COLOR" caption="Updated Color" allowEditing={false} allowFiltering={false} allowSorting={false}
                        cellRender={colorCellRender}
                    />
                    {rejectionList && rejectionList[0].SRC_SIZE !== "IGNORE" ?
                        <Column dataField="STM_SIZE" caption="Updated Size" allowEditing={false} allowFiltering={false} allowSorting={false}
                            cellRender={cellSizeRender}
                        /> :
                        <></>
                    }

                    <Column dataField="QS" caption="Sls U" allowEditing={false} allowFiltering={false} allowSorting={true} format="###,###,##0" />
                    <Column dataField="QA" caption="OH U" allowEditing={false} allowFiltering={false} allowSorting={true} format="###,###,##0" />

                    <Column
                        type="buttons"
                        cellRender={(options) => (
                            <div className="dx-data-grid-cell-value">
                                {/* {[options.data.CLIENT, options.data.TRADE_PARTNER_ID, options.data.SRC_STYLE, options.data.SRC_COLOR, options.data.SRC_SIZE] in updatedRows && updatedRows[[options.data.CLIENT, options.data.TRADE_PARTNER_ID, options.data.SRC_STYLE, options.data.SRC_COLOR, options.data.SRC_SIZE]].process_flag === 'D' ? <Button
                                hint="Cancel"
                                icon="close"
                                onClick={() => {
                                    let key = [options.data.CLIENT, options.data.TRADE_PARTNER_ID, options.data.SRC_STYLE, options.data.SRC_COLOR, options.data.SRC_SIZE];
                                    let curData = key in updatedRows ? JSON.parse(JSON.stringify(updatedRows[key])) : JSON.parse(JSON.stringify(options.data));
                                    curData.process_flag = 'N';

                                    updatedRows[key] = curData;
                                    dataGridRef.current.instance.repaintRows(options.rowIndex);
                                }}
                            /> : <Button
                                hint="Delete"
                                icon="trash"
                                onClick={() => {
                                    let key = [options.data.CLIENT, options.data.TRADE_PARTNER_ID, options.data.SRC_STYLE, options.data.SRC_COLOR, options.data.SRC_SIZE];
                                    let curData = key in updatedRows ? JSON.parse(JSON.stringify(updatedRows[key])) : JSON.parse(JSON.stringify(options.data));
                                    curData.process_flag = 'D';

                                    updatedRows[key] = curData;
                                    dataGridRef.current.instance.repaintRows(options.rowIndex);
                                }}
                            />} */}

                                <Button
                                    hint="Undo"
                                    icon="undo"
                                    onClick={() => {
                                        let key = [options.data.CLIENT, options.data.TRADE_PARTNER_ID, options.data.SRC_STYLE, options.data.SRC_COLOR, options.data.SRC_SIZE, options.data.REJECTION_CAUSE];
                                        let curData = key in updatedRows ? JSON.parse(JSON.stringify(updatedRows[key])) : JSON.parse(JSON.stringify(options.data));
                                        curData.PROCESS_FLAG = curData.PROCESS_FLAG === 'P' ? 'N' : 'P';
                                        if ('STATUS' in curData) {
                                            if (curData.STATUS === 1) {
                                                curData.STATUS = -1
                                            }
                                            else {
                                                delete curData.STATUS;
                                            }
                                        }
                                        else {
                                            curData.STATUS = -1;
                                        }

                                        updatedRows[key] = curData;
                                        dataGridRef.current.instance.repaintRows(options.rowIndex);
                                    }}
                                />
                                <Button
                                    icon="info"
                                    className={`info-button-${options.row.loadIndex}`}
                                />
                                <Tooltip
                                    target={`.info-button-${options.row.loadIndex}`}
                                    position="left"
                                    showEvent="mouseenter"
                                    hideEvent="mouseleave"
                                >
                                    <div>Week End: {options.data.WEEK_ENDING_DATE}</div>
                                    <div>Department: {options.data.DEPARTMENT}</div>
                                    <div>Filename: {options.data.FILE_NAME}</div>
                                </Tooltip>
                            </div>
                        )}
                    />
                </DataGrid>
                :
                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress className={classes.spinnerIcon} style={{ margin: '-4px' }} />
                    <Typography style={{ display: 'inline-block', margin: '0px 0px 0px 25px', fontSize: '14px' }} >Loading Colors...</Typography>
                </Box>
            }
        </Card>

    );
};

export default RejectionTable;